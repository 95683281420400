.document-upload-lable {
  width: 100%;
  position: relative;
}
.document-upload-lable > div {
  width: 100%;
}
#document-upload{
   position: absolute;
   top: 15px;
   right: -77px; 
}
.my-account h2 {
    font-size: 23px;
    font-weight: 600;
}
.doclabel{
    border: 1px solid;
    border-radius: 5px;
    padding: 4px;
    padding-top: 1px;
    color: forestgreen;
}
@media (max-width: 1150px) {
    .textfield-row-main div div{
        width: 100%;
        margin-bottom: 10px;
    }
    .textfield-row-main {
        margin-bottom: 0;
    }
    #document-upload{
        display: none;
    }
    .fa-file.mobile {
        position: absolute;
        top: 22px;
        right: 20px;
        display: block!important;
        color: grey;
    }
}