@media (max-width: 991px) {
    .listing-search{
        margin-bottom: 15px;
    }
    .doctor-details{
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .margin-change {
        margin-bottom: 0;
    }
}