@media (max-width: 991px) {
    .header-mainMenu2 img {
        max-width: 150px;
    }
    .header-top .wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
    .navbar-light .navbar-toggler {
        border-color: transparent;
    }
    .navbar-toggler:focus {
        box-shadow: unset;
    }
    .header-mainMenu .main_menu_list_ul {
        display: block;
        padding-left: 0;
    }
    .header-mainMenu .main_menu_list_ul li{
        margin-bottom: 15px;
    }
    .header-mainMenu {
        display: block;
        margin-top: 20px;
    }
    .signin-bt{
        margin-top: 40px;
        max-width: 150px;
    }
    .btn-close:focus {
        box-shadow: unset;
    }
}

@media (max-width: 767px) {

}