* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

.list_page_layout {
  display: block;
}

.mainP.HomePage {
  background: #f1f3f4;
}

.wrapper {
  width: 1280px;
  margin: 0 auto;
  max-width: 90%;
}

.left-sidebar_listing.sticky {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 30px;
  border-radius: 0;
}

.listing-main {
  padding: 5px 0;
}

.main-detain-div {
  margin: 10px 0;
}

.left-sidebar_listing {
  background: #fff;
  border-radius: 6px;
  padding: 30px 20px;
  box-sizing: border-box;
  min-height: auto;
  padding-left: 0;
  padding-bottom: 10px;
}

.left-sidebar_listing h2 {
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #4b4b4b;
  font-family: Poppins, sans-serif;
  margin: 0;
}

.filter-box {
  margin: 30px 0;
  border-bottom: 1px solid #f1f1f1;
}

.filter-box ul {
  padding: 0;
  margin: 0;
}

.filter-box li {
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b4b4b;
  font-family: Poppins, sans-serif;
  margin: 15px 0;
}

.filter-box label {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.filter-box h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #555;
  margin: 0;
  margin-bottom: 20px;
}

.filter-box li span {
  max-width: 100%;
  word-break: break-all;
  font-size: 14px;
  font-weight: 400;
}

.filter-box label {
  font-size: 14px;
}

.doctor-details {
  border-radius: 10px;
  background: #fff;
  padding: 10px;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.05);
  height: auto;
}

.filter-box:last-child {
  border-bottom: 0;
}

.doctor-details .img-first {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
}

.doctor-name {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.doctor-name h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.doctor-details h5 {
  color: #000;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
}

.booking-fees {
  color: #000;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fee-per-person {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Booking-num {
  color: #000;
  font-family: Montserrat;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 2.6;
}

.profile-view {
  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.05);
  padding: 8px;
  width: 100%;
  color: #d72045;
  border: 1px solid #d72045;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.book-now.btn.btn-primary {
  color: #fff;
  border-radius: 8px;
  background: #d72045;
  border: 1px solid #d72045;
  padding: 8px;
  width: 100%;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.book-now.btn.btn-primary:hover {
  color: #fff;
  border-radius: 8px;
  background: #d72045;
  border: 1px solid #d72045;
  padding: 8px;
  width: 100%;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.book-now.btn.btn-primary:active {
  color: #fff;
  border-radius: 8px;
  background: #d72045 !important;
  border: 1px solid #d72045 !important;
  padding: 8px;
  width: 100%;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.book-now.btn.btn-primary:focus {
  box-shadow: unset;
}

.listing-header {
  margin: 10px 0;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.header-brudcrumb-i-listing {
  color: grey;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  font-family: Montserrat;
  text-transform: capitalize;
}

.listing-header .btn-primary {
  background: #d72045;
  border: 1px solid #d72045;
}

.listing-header .btn-primary:hover {
  background: #d72045;
  border: 1px solid #d72045;
}

.listing-header .btn-primary:focus {
  box-shadow: unset !important;
  background: #d72045 !important;
  border: 1px solid #d72045 !important;
}

.listing-header .btn-primary:active {
  background: #d72045;
  border: 1px solid #d72045;
}

.listing-search {
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  margin-top: 10px;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.05);
}

.form-c input,
.form-c select {
  width: 32%;
  color: #333;
  padding: 10px 5px;
  font-size: 13px;
  border-radius: 4px;
  border: 1px solid #dfd8d8;
  width: 100%;
  margin-bottom: 15px;
}

.pos-but-loader {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: #fff;
  background: #d72045;
  border-radius: 4px;
  border: unset;
  font-family: Poppins, sans-serif;
  cursor: pointer;
  min-height: 35px;
  display: block;
  outline: 0;
}

.pos-but-loader:hover {
  background: transparent;
  border: 1px solid #d72045;
  color: #d72045;
}

.form-c input,
.form-c select:focus-visible {
  outline: 0 !important;
}

.margin-change {
  margin-bottom: 20px;
}

/* header */
.header-top {
  background: #000;
  text-align: right;
}

.top-box {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 5px;
  height: 40px;
}

.top-box * {
  color: #fff;
  font-size: 12px;
}

.top-box.search-bar {
  border: unset;
}

.top-box.search-bar input {
  background: transparent;
  border: unset;
}

.header-top .wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.mobile_top_form,
.showoNLYmOB {
  display: none;
}

.top-box a {
  text-decoration: none;
}

.list_page_layout .menu {
  background: #fff;
}

.menu {
  padding: 10px 0;
}

.menu-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-main2 a {
  display: block;
}

.header-main1 {
  display: flex;
  align-items: center;
  gap: 35px;
}

.header-main1 ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin-bottom: 0;
}

.DetailPage_ .header-main1 li a,
.list_page_layout .header-main1 li a {
  color: #333;
}

.list_page_layout .header-main1 li a {
  color: #333 !important;
}

.header-main2 img {
  max-width: 240px;
}

.signin-bt.dropdownCls {
  position: relative;
}

.header-main1 a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333;
  gap: 7px;
}

.signin-bt a {
  background: #d72045;
  color: #fff !important;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  padding: 0 10px;
}

.drp-dwn {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  width: 200px;
  padding: 12px;
  display: none;
  z-index: 99;
}

.activeShow {
  display: block !important;
}

.drp-dwn a {
  background: unset;
  background-color: #fff !important;
  font-weight: 400;
  text-align: left !important;
  font-size: 13px;
  width: 100%;
  display: block;
  padding: 0;
  height: auto;
  padding: 3px;
  margin: 5px 0;
}

.list_page_layout .header-main1 li a {
  color: #333;
  font-family: Poppins, sans-serif;
}

.menu {
  background: #fff;
}

a.router-link-exact-active.router-link-active {
  color: #d72045 !important;
}

.header-main1 a:hover {
  color: unset;
}

.playstore-header {
  width: 20px;
  height: auto;
  cursor: pointer;
  object-fit: cover;
}

/* header-end */
.modal-dialog {
  position: absolute !important;
  width: 100% !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.category-listing {
  width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  margin: 20px 0 15px;
}

.catogryes {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.catogary-inner {
  position: relative;
  width: 100%;
}

.catogary-inner img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: auto;
  display: block;
  cursor: pointer;
}

.category-text p {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 5px;
  text-transform: capitalize;
  cursor: pointer;
  word-break: break-word;
}

/* .category-main{
    width: 10%;
} */
.hideOnmob {
  display: none;
}

.drp-dwn {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.drp-dwn a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.drp-dwn a:hover {
  background-color: #f1f1f1;
}

.location {
  width: 13px;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #d72045;
  background-color: #d72045;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.modal-header.tab-html {
  padding: 0 !important;
}

/* Style the buttons inside the tab */
.tab button {
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 16px;
  color: #fff;
  text-align: center;
  font-weight: normal;
  font-family: Montserrat;
  line-height: normal;
  border: none;
  background: transparent;
  border-bottom: 1px solid #fff;
  border: none;
}

.tab button p {
  font-size: 8px;
  color: #fff;
  text-align: center;
  font-weight: normal;
  font-family: Montserrat;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 2px;
}

.tab button:hover {
  border: none;
  background-color: transparent !important;
  border-bottom: 3px solid #fff;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.tab-content {
  padding: 20px;
  border-radius: 10px;
  background: #fbe1e2;
}

.tab-content p {
  font-size: 16px;
  text-align: center;
  font-weight: normal;
  font-family: Montserrat;
  line-height: normal;
  color: #fea3a4;
  margin-bottom: 10px;
}

.tab-content span {
  font-size: 12px;
  text-align: center;
  font-weight: normal;
  font-family: Montserrat;
  line-height: normal;
  color: #fea3a4;
  margin: auto;
  display: block;
}

.tab-content.active {
  background-color: #00C797;
}

.tab-content.active p {
  font-size: 16px;
  text-align: center;
  font-weight: normal;
  font-family: Montserrat;
  line-height: normal;
  color: #fff;
  margin-bottom: 10px;
}

.tab-content.active span {
  font-size: 12px;
  text-align: center;
  font-weight: normal;
  font-family: Montserrat;
  line-height: normal;
  color: #fff;
  margin: auto;
  display: block;
}

.modal-body.tab-list {
  padding: 2rem !important;
}

.listing-service-main .footer-main {
  margin-top: 0;
  background: #e9eaeb;
}

.no_data_msg {
  text-align: center;
  font-size: 16px;
  margin-top: 30px;
}
/* Tab style */
.button-main .react-tabs_tab-panel.react-tabs_tab-panel--selected {
  padding: 10px 20px;
}

.button-main ul {
  background: #d72045;
  text-align: center;
}

.button-main ul li {
  width: 33.33%;
}

.button-main ul li {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  font-family: Montserrat;
  line-height: normal;
}

.button-main ul li::after {
  width: 33.33%;
  left: 15px !important;
}

.button-main ul li p {
  margin-bottom: 0;
  font-size: 12px;
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-family: Montserrat;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 2px;
}

.button-main .react-tabs__tab--selected {
  background: unset;
  border-color: #fff;
  border-bottom: 3px solid #fff;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
}

.button-main .modal-dialog {
  position: absolute !important;
  width: 100% !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0;
}

.button-main .modal-content {
  border: unset;
}

.availblae-active-disactive {
  padding: 15px;
  color: #B54069;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  border-radius: 20px;
  background: #FBE1E2;
  margin-bottom: 25px;
  padding: 0 10px;
  text-align: center;
}

.availblae-active {
  padding: 15px;
  color: #189E82;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  border-radius: 20px;
  background: #E2F7F2;
  margin-bottom: 10px;
  text-align: center;
}

.availblae {
  padding: 15px;
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  border-radius: 20px;
  background: linear-gradient(0deg, #00C797 0%, #00C797 100%), #E2F7F2;
  margin-bottom: 10px;
  text-align: center;
}

.button-main .react-calendar {
  position: absolute;
  z-index: 999;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
  width: 280px;
}

.button-main .react-calendar__tile {
  padding: 5px;
}
.availblae-active-disactive {
  padding: 15px;
  color: #B54069;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  border-radius: 20px;
  background: #FBE1E2;
  margin-bottom: 20px;
}
.button-main .react-tabs_tab-panel.react-tabs_tab-panel--selected {
  padding: 10px 20px !important;
}
.react-tabs__tab-panel--selected{
  padding: 10px 20px 0;
}
.large-2 {
  height: 250px;
  overflow-y: scroll;
}

.large-2::-webkit-scrollbar-track {
  /* border: 1px solid #000; */
  padding: 2px 0;
  /* background-color: #404040; */
}

.large-2::-webkit-scrollbar {
  width: 3px;
}

.large-2::-webkit-scrollbar-thumb {
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  /* background-color: #737272; */
  /* border: 1px solid #000; */
}

/* Tab style : end*/

.pagination .active > .page-link {
  background-color: #ef0035 !important;
  border: none;
}
.loading-image br{
  display: none!important;
}
.react-multi-carousel-list {
  /* display: list-item !important; */
  align-items: center;
  overflow: hidden;
  position: relative;
}
.my-account label {
  display: unset!important;
}