@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  /* overflow-y: auto !important; */
  padding-right: 0 !important;
}

a {
  text-decoration: none;
}

h2,
a,
p,
span,
h1,
h3 {
  word-break: break-all;
}

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

input:focus {
  outline: none;
}

a,
p,
input,
selec,
textarea {
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
}

/* .header-top {
    background: #3E0000;
    text-align: right;
} */
.header-top {
  /* background: #2d2d2d; */
  background: #440011;
  text-align: right;
}

.menu {
  padding: 10px 0;
}

.wrapper {
  width: 1280px;
  margin: 0 auto;
  max-width: 90%;
}

.header-top .wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.top-box {
  display: flex;
  align-items: center;
  gap: 10px;
  border-right: 1px solid #b2b2b2;
  padding-right: 20px;
  height: 40px;
}

.top-box * {
  color: #a9a9a9;
  font-size: 12px;
}

.tile-h {
  text-align: center;
  margin-bottom: 40px !important;
}

.top-box.search-bar {
  border: unset;
}

.top-box.search-bar input::placeholder {
  color: #a9a9a9;
  font-size: 12px;
}

.top-box.search-bar input {
  background: transparent;
  border: unset;
}

.menu-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-main2 img {
  max-width: 240px;
}

.header-main1 > ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.header-main1 {
  display: flex;
  align-items: center;
  gap: 35px;
}

.signin-bt a svg {
  height: 12px;
}

.signin-bt a {
  /* background: #C70419; */
  background: linear-gradient(
    90deg,
    rgb(255, 30, 86) 0%,
    rgb(255 71 101) 54%,
    rgb(255 26 90) 100%
  );
  color: white;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  /* background: linear-gradient(180deg, #FF263C 0%, #FF263C 100%); */
  padding: 0 10px;
}

.HomePage .header-main {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}

img.bg-img {
  position: absolute;
  right: -4%;
  /* top: 30px; */
  z-index: 1;
  max-width: 1250px;
  width: 100%;
  max-height: 100%;
  bottom: 0;
  height: 100%;
}

.banner-content {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  max-height: 100%;
  margin-top: 20px;
}

.banner-content img {
  max-width: 600px;
}

.form-sec {
  margin-left: 0;
  margin-bottom: 10%;
}

.SearchForm {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 8px 2px 8px 8px rgb(0 0 0 / 29%);
  border-radius: 15px;
  width: 750px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.SearchForm {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 8px 2px 8px 8px rgb(0 0 0 / 29%);
  border-radius: 15px;
  width: 850px;
  padding: 20px;
  box-sizing: border-box;
}

.banner-content img {
  max-width: 590px;
  position: absolute;
  right: -10%;
  bottom: 11%;
}

.search_tab ul li.active {
  color: rgb(255 28 75);
}

.search_tab ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  cursor: pointer;
}

.search_tab ul li:hover {
  color: rgb(255 28 75);
}

.search_tab ul svg {
  height: 14px;
  width: 12px;
}

.search_tab ul {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  color: #333333;
  gap: 15px;
  align-items: center;
  margin: 0;
}

.form-c select,
.form-c input {
  width: 25%;
  color: #333333;
  padding: 5px;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
}

.form-c input::placeholder {
  color: #333333;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.form-c {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
}

select:focus {
  outline: none;
}

.SearchForm button {
  width: 98%;
  /* width: 32%; */
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: #ffffff;
  background: #c70419;
  background: #ff283e;
  border-radius: 4px;
  border: unset;
  font-family: "Poppins", sans-serif;
  /* margin-top: 10px; */
  cursor: pointer;
  min-height: 35px;
  display: block;
}

.SearchForm button:hover {
  background: #ff1a5a;
}

.form-c select,
.form-c input {
  /* width: 25%; */
  width: 32%;
  color: #333333;
  padding: 10px 5px;
  font-size: 13px;
  border-radius: 4px;
  border: 1px solid #949494;
}

.form-c input::placeholder {
  font-size: 13px;
}

input {
  outline: unset;
}

/* .form-sec {
    margin-left: 10%;
} */
/* .round {
    position: absolute;
    right: -10%;
    width: 520px;
    height: 118px;
    background: white;
    border-radius: 50%;
    bottom: -7px;
} */
.banner {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: #ff6a7a17;
  background: #ffd0da4a;
  padding-bottom: 50px;
}

.homeBanner {
  height: 640px;
  position: relative;
}

.round {
  position: absolute;
  right: -9%;
  width: 520px;
  height: 118px;
  background: #fff2f3;
  border-radius: 50%;
  bottom: -3%;
  /* z-index: 4; */
}

.single_box img {
  max-width: 90px;
}

.single_box {
  text-align: center;
}

/* .single_box img {
    max-width: 120px;
    display: block;
    margin: 0 auto;
    height: 120px;
    object-fit: contain;
} */
.single_box img {
  max-width: 80%;
  display: block;
  margin: 0 auto;
  height: 120px;
  object-fit: cover;
}

h2.tile-h {
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 37px !important;
  color: #c70419;
  margin: 0;
  color: #ff3447;
}

.title-sec-s {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;
}

.title-sec-s button {
  background: #c70419;
  background: #ff283e;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  padding: 10px 15px;
  border: none;
}

.title-sec-s button:hover {
  background: #ff1a5a;
}

.featured_speciality {
  padding: 30px 0;
}

.single_box a {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(12, 57, 123, 0.78);
  display: block;
  color: rgb(255 113 160);
}

.single_box h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  font-family: "Poppins", sans-serif;
  margin: 15px 0 12px;
}
.single_box p {
  cursor: pointer;
  margin: 15px 0 12px;
}
.single_box p:hover {
  color: red;
  margin: 15px 0 12px;
}

.header-main1 a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #f0f0f0;
  gap: 7px;
}

button.owl-dot.active span {
  /* background: #C70419 !important; */
  width: 12px !important;
  height: 12px !important;
  background: #ff3a75 !important;
}

button.owl-dot span {
  background: rgb(255 58 117 / 55%) !important;
  width: 12px !important;
  height: 12px !important;
}

.owl-dots {
  /* margin-top: 50px !important; */
}

.ads-main2 {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 50px;
}

.ads-main2 img {
  box-shadow: 0px 1px 6px 3px rgb(0 0 0 / 25%);
  width: 50%;
  max-height: 150px;
  object-fit: cover;
}

.ad-sec {
  position: relative;
}

.ad-sec .wrapper {
  position: relative;
  z-index: 9;
}

img.bg2 {
  position: absolute;
  left: 0;
  z-index: 0;
  top: 12%;
  max-width: 500px;
}

.steps .step div .pos-img {
  position: absolute;
  top: 54%;
  left: 53%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 55px;
}

.steps .step div {
  position: relative;
}

.steps {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  max-width: 70%;
  margin: 5% auto 0px;
}

.step div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step p {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #292929;
  max-width: 155px;
  word-break: break-word;
}

.booking_step h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #333333;
}

.booking_step {
  margin: 100px 0 50px;
}

.step div img:nth-child(1) {
  max-width: 125px;
}

.top_tab {
  padding: 50px 0 0;
}

.top_tab h2 {
  text-align: center;
  display: block;
  width: 100%;
}

.profile_img img {
  /* width: 85%;
    height: 85%;
    object-fit: contain; */
  display: block;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.profile_img {
  width: 145px;
  height: 145px;
  overflow: hidden;
  background: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: -58px;
  position: relative;
  z-index: 99;
}

.profile_det P {
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.profile_det span {
  font-weight: 300;
  font-size: 17px;
  line-height: 25px;
  color: #4b4b4b;
}

.profile_det h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  margin: 0;
}

p.exp_adrsp {
  font-weight: bold;
  padding: 10px 0 5px;
  word-break: break-word;
}

.profile_det {
  border: 1px solid rgb(221 221 221);
  padding: 20px;
  max-width: 325px;
  padding-left: 75px;
  box-sizing: border-box;
  padding-right: 20px;
  background: #f7f7f7;
  width: 100%;
  height: 150px;
}

.single_profile {
  display: flex;
  align-items: center;
}

.tab-co .single_profile {
  width: 32%;
}

.tab-co {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  min-height: 350px;
  align-items: flex-start;
}

.tab_head ul li {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}

.tab_head ul li span {
  cursor: pointer;
}

.tab_head ul {
  justify-content: flex-start;
  display: flex;
  gap: 25px;
  list-style: none;
  padding: 10px 0;
  max-width: 100%;
  overflow: auto;
  margin: 10px 0 0;
}

.bookings_top .title-sec-s {
  margin-bottom: 10px;
}

.tab_head {
  margin-bottom: 50px;
}

.tab_head li.active {
  color: #d63930 !important;
}

.tab_head li.active::after,
.tab_head li.active:hover:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #d63930;
  display: block;
  position: absolute;
  left: 0;
  bottom: -7px;
}

.book_now_section {
  display: flex;
  /* background: radial-gradient(64.69% 211.11% at 96.25% 35.31%, #C70419 0%, rgba(199, 4, 25, 0.44) 100%); */
  position: relative;
  margin: 0;
  background: radial-gradient(
    90.62% 435.33% at 3.92% 82.09%,
    #ff5530 0%,
    #ff805f 49.89%
  );
}

.book_area {
  display: flex;
  align-items: center;
}

img.bg-book {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

img.all_b {
  margin-top: -7%;
  max-height: 600px;
}

.book_area > div {
  margin-left: auto;
  padding-left: 100px;
}

.book_area p {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ededed;
  margin: 10px 0 30px;
}

.book_area h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 47px;
  color: #ffffff;
  margin: 0;
}

.book_area button {
  /* background: #65000B; */
  background: #ff372c;
  border-radius: 4px;
  padding: 10px 20px;
  color: white;
  border: unset;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

/* .bookings_top {
    background: #F3F3F4;
    padding: 50px 0 180px;
} */
.bookings_top {
  background: #ffbfad30;
  padding: 50px 0 180px;
  margin-top: 60px;
}

.testi_monial {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment-block {
  background: #ffffff;
  box-shadow: 1px 1px 3px 3px rgb(0 0 0 / 35%);
  border-radius: 10px;
  margin: 5px;
  padding: 20px;
}

span.quote svg {
  width: 30px;
  display: inline-block;
  height: 30px;
  color: black;
}

span.quote.quote-right {
  display: block;
  text-align: right;
  margin: 0;
}

.comment-sec h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 37px;
  color: #333333;
}

.comment-sec p {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
  max-width: 90%;
}

.comment-block p {
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  width: 100% !important;
  max-width: 100% !important;
  font-family: "Montserrat", sans-serif;
}

.comment-block span {
  display: block;
  width: 100%;
  text-align: right;
  margin-bottom: 16px;
  font-size: 13px;
  color: #7e7e7e;
}

span.quote.quote-left {
  text-align: left;
}

.comment-sec {
  width: 60%;
}

.testi_monial > img {
  width: 25%;
  margin-left: 5%;
}

.main_cm {
  background: transparent;
  margin-top: 10px;
  padding-top: 50px;
}

.comments {
  margin-top: 50px;
}

.footer-main {
  position: relative;
}

.footer-main > img {
  position: absolute;
  width: 100%;
  height: 80%;
  left: 0;
  bottom: 0;
}

.footer-main {
  position: relative;
  height: 600px;
  display: flex;
  align-items: center;
  background: transparent;
  margin-top: -10%;
}

.footer-menu {
  width: 70%;
  position: relative;
}

.menu-f {
  width: 50%;
  display: flex;
  gap: 100px;
  justify-content: space-between;
}

.menu_footer_block h3 {
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  color: white;
  margin: 0 0 15px;
}

.menu_footer_block li,
.menu_footer_block li a {
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
}

.menu_footer_block li {
  padding: 6px;
}

.menu_footer_block ul {
  padding: 0;
  margin: 0;
}

.homeBanner > svg {
  position: absolute;
  right: 0;
  top: 0;
  /* color: red !important; */
  /* background: black; */
  fill: black;
}

.ad-sec > svg {
  position: absolute;
  left: -390px;
  z-index: -1;
  top: 2%;
  max-width: 75%;
  width: 80%;
  height: 820px;
}

.book_area svg {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  max-width: 550px;
}

/* .footer-main svg {
    position: absolute;
    left: -9%;
    top: 0%;
    width: 11%;
    height: 800px;
    z-index: 0;
} */
.footer-main {
  overflow: hidden;
}

.footer-main svg {
  width: 1920px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.bg-color-sec {
  background: #ffeaec;
}

.footer-menu {
  margin-top: 15% !important;
  display: flex;
  justify-content: space-between;
}

.social a,
.social a svg {
  display: block;
  width: 15px;
  color: white;
  position: relative;
  padding: 0;
}

.mail-s {
  width: 33%;
  text-align: center;
}

.sub_sc img {
  position: absolute;
  right: 0;
  top: 0;
}

.sub_sc {
  position: relative;
  margin-top: 30px;
}

.sub_sc input {
  background: rgba(255, 255, 255, 0.76);
  border-radius: 36px;
  padding: 15px 58px 15px 25px;
  border: unset;
  width: 100%;
  box-sizing: border-box;
  color: black;
}

.social svg path {
  color: black;
  fill: red;
}

.footer-bt {
  background: #3e0000;
  display: block;
  color: #ffffff87;
  text-align: center;
  font-size: 12px;
  padding: 5px;
  font-weight: 200;
}

.social a svg {
  display: block;
  width: 15px;
  height: 15px;
  color: white;
  position: relative;
  padding: 0;
}

.social a {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  height: 30px;
  border-radius: 50%;
}

.social {
  display: flex;
  gap: 10px;
  padding: 25px 0;
  justify-content: center;
}

.layout-sec {
  min-height: 50vh;
  display: flex;
  /* align-items: center; */
  padding-top: 50px;
  margin-bottom: 60px;
}

.inner_page_layout .header-main1 li a {
  color: #333333 !important;
}

.inner_page_layout
  .header-main1
  li
  a.router-link-exact-active.router-link-active {
  color: #ff2046 !important;
}

.signin {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.authform {
  width: 45%;
}

.auth-right {
  width: 40%;
}

.form label {
  width: 100%;
  display: block;
  padding: 10px 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #1a1a1a;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.form input {
  display: block;
  width: 100%;
  padding: 8px;
  border: 1px solid #949494;
  border-radius: 4px;
  margin-top: 0px;
  box-sizing: border-box;
}

.form {
  display: block;
  max-width: 100%;
}

.inner_page_layout .footer-main {
  margin: 0;
}

.round_msg {
  background: #bbbbbb66;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 310px;
  height: 310px;
  border-radius: 50%;
  position: absolute;
  right: 45px;
  top: 40%;
  z-index: 1;
}

.round_msg h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #555555;
  width: 100%;
  text-align: center;
  max-width: 90%;
  margin: 0 auto !important;
}

.round_msg p {
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  text-align: center;
  color: #555555;
  width: 100%;
  text-align: center;
}

.round_msg p,
.round_msg h4 {
  margin: 0;
}

.round_msg p {
  margin: 10px 0;
}

.authform > div {
  width: 100%;
  background: white;
}

.authform {
  display: flex;
  align-items: center;
  position: relative;
}

.round_msg a {
  background: #ff2045a1;
  border-radius: 30px;
  display: block;
  padding: 10px;
  text-align: center;
  color: #dedede;
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 17px;
  font-weight: bold;
  max-width: 140px;
  margin: 20px auto 0;
  /* box-shadow: 1px 1px 4px 4px rgb(205 205 205); */
}

.round_msg a:hover {
  box-shadow: 1px 1px 1px 1px rgb(205 205 205);
}

.authform > div {
  box-shadow: 1px 1px 4px 4px rgb(0 0 0 / 25%);
  padding: 30px;
  box-sizing: border-box;
  width: 100%;
  background: #ffffff1a;
  padding-bottom: 30px;
  background: white;
}

.inner_page_layout .header-main {
  background: white;
}

.inner_page_layout {
  background: rgb(176 176 176 / 12%);
}

.form button {
  background: #c70419;
  border-radius: 5px;
  width: 100%;
  color: white;
  border: unset;
  padding: 10px;
  margin-top: 20px;
  font-size: 20px;
}

img.pos-image-auth {
  width: 100%;
  object-fit: contain;
  margin-right: auto;
  width: 35%;
  height: 420px;
  position: relative;
  z-index: 9;
}

.authTitle h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  margin: 0 0 10px;
}

.error {
  color: red;
  font-size: 14px;
  width: 100%;
  text-align: center;
  padding-top: 20px;
}

span.forfgotp {
  display: block;
  text-align: right;
  font-size: 12px;
  /* padding: 10px 0 5px; */
  color: #000000bf;
}

.forgot img.pos-image-auth {
  margin-left: auto;
}

.box_listing_main {
  display: flex;
  gap: 35px;
  align-items: flex-start;
}

.list_page_layout {
  background: rgb(176 176 176 / 12%);
  display: block;
}

.list_page_layout .menu {
  background: white;
}

.listt-sec {
  padding: 20px 0 100px;
}

.list_page_layout div#Footer .footer-main {
  margin-top: 0;
}

.left-sidebar {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 23%;
  max-width: 300px;
  padding: 25px 20px;
  box-sizing: border-box;
  min-height: 90vh;
}

.list_view {
  width: 73%;
  margin-left: auto;
}

.left-sidebar h2 {
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #4b4b4b;
  font-family: "Poppins", sans-serif;
  margin: 0;
  margin-bottom: 25px;
}

.filter-box input {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.filter-box {
  margin: 30px 0 0;
}

.filter-box h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #555555;
  margin: 0;
  margin-bottom: 20px;
}

.filter-box ul {
  padding: 0;
  margin: 0;
}

.filter-box input[type="checkbox"],
.filter-box input[type="radio"] {
  max-width: 20px;
  margin-top: 7px;
}

.filter-box li {
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b4b4b;
  font-family: "Poppins", sans-serif;
  margin: 10px 0;
  /* display: flex;
    gap: 5px;
    align-items: flex-start; */
}

.filter-box li ul {
  padding-left: 20px;
}

.filter-box li ul li {
  font-size: 14px;
}

.filter-box label {
  display: flex;
  gap: 5px;
  align-items: flex-start;
}

.filter-box li span {
  max-width: 80%;
  word-break: break-all;
}

.service_block {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.provider {
  display: flex;
  gap: 15px;
  /* align-items: center; */
  justify-content: space-between;
}

.basic_info h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  font-family: "Poppins", sans-serif;
  margin: 0;
  margin-bottom: 5px;
  word-break: break-all;
}

.basic_info span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3e0000;
}

.qualifictn {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  font-style: italic;
  padding: 10px 0 3px;
}

span.yellow {
  color: #f69502;
}

.grey {
  color: #4b4b4b;
}

.star-rate {
  display: flex;
  gap: 5px;
}

.star-rate {
  display: flex;
  gap: 5px;
  padding: 12px 0;
}

p.exp {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin: 0;
}

button.view_profile {
  background: #ff6b35;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  border: unset;
  padding: 8px 20px;
  margin-top: 0px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  width: 170px;
  padding: 10px;
}

.s_img img {
  width: 100%;
}

.s_img {
  width: 20%;
}

.s_img img {
  width: 100%;
  max-height: 200px;
  min-height: 200px;
  object-fit: cover;
  height: 100%;
}

.booking_info_b {
  width: 35%;
  margin-left: auto;
  padding-top: 15px;
  padding-left: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.service_block .s_img {
  max-height: 200px;
}

.tab-head_date div span {
  display: block;
  width: 100%;
}

.basic_info {
  width: 40%;
  border-right: 1px solid #e9e9e9;
  padding-top: 15px;
}

.loc svg {
  margin-top: 5px;
  color: #4b4b4b;
}

.loc {
  display: flex;
  gap: 10px;
  color: #4b4b4e;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

span.fees_b {
  display: block;
  width: 100%;
  margin-top: auto;
}

.loc p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #4b4b4b;
  margin: 0;
  max-width: 90%;
  word-break: break-word;
}

button.bookNow_b {
  background: #ff6057;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  border: unset;
  width: 170px;
  padding: 10px;
  margin-top: 0;
  margin-bottom: 0px;
}

span.fees_b {
  font-weight: 500 !important;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.detail_banner {
  /* background: #FE6A7A; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 80vh;
  max-height: 300px;
  padding-bottom: 70px;
  box-sizing: border-box;
}

.image_round img {
  width: 90%;
  height: 90%;

  object-fit: cover;
  border-radius: 50%;
  object-position: center;
}

.image_round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border: 1px solid white;
  border-radius: 50%;
  position: relative;
}

.image_round svg {
  color: #59b153;
  position: absolute;
  bottom: -9px;
  background: white;
  border-radius: 50%;
  height: 35px;
  left: 43%;
}

.round-info h2 {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #440011;
  text-align: center;
  display: block;
  margin: 10px 0 2px;
}

.round-info p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3e0000;
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
  margin: 0;
}

.box_flex {
  position: relative;
  margin-top: -45px;
  z-index: 1;
}

.box_flex {
  display: flex;
  gap: 25px;
}

.box_1_d {
  width: 60%;
}

.round-info .star-rate .yellow {
  color: #ff5a0b;
}

.round-info .star-rate {
  justify-content: center;
}

.detail_page_personal h3 {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #333333;
  margin: 0;
  margin-bottom: 10px;
}

.detail_page_personal p.exp {
  padding: 10px 0 20px;
}

p.short {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0;
}

.buttons_det button {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5b5b5b;
  background: #d7d7d7;
  border: unset;
  padding: 5px 10px;
}

.buttons_det {
  margin-top: 25px;
  display: flex;
  gap: 10px;
}

.DetailPage_ .menu {
  background: white;
}

.DetailPage_ {
  background: #e9e9e9;
}

.DetailPage_ .footer-main {
  margin: 0;
}

.detail_page_personal {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;
}

.box_2_d {
  width: 40%;
}

.booking_date_det img {
  width: 100%;
}

.booking_date_det {
  background: #ffffff;
  border-radius: 10px;
  padding: 25px;
  box-sizing: border-box;
}

.booking_date_det h2 {
  margin: 0;
}

.rate {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fc838e;
  margin: 15px 0 15px;
}

.location_map iframe {
  width: 100%;
  max-height: 255px;
  margin: 20px 0;
}

.Review_box {
  background: rgba(201, 13, 34, 0.16);
  padding: 30px;
  height: 500px;
  overflow: hidden;
}

.im-rev img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #b8b8b8;
}

.im-rev .star-rate {
  gap: 0px;
  justify-content: center;
}

.im-rev .star-rate svg {
  width: 13px;
}

.rev_single {
  display: flex;
  gap: 20px;
}

.comment {
  width: 80%;
}

.im-rev {
  width: 20%;
  text-align: center;
}

.top_re {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.top_re h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.top_re span {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4b4b4b;
}

p.com {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4b4b4b;
  margin: 0;
}

.im-rev .star-rate {
  gap: 0px;
  justify-content: center;
  padding: 0;
}

.review_scroll {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-right: 15px;
}

/* width */
.review_scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.review_scroll::-webkit-scrollbar-track {
  background: #c90d225e;
}

/* Handle */
.review_scroll::-webkit-scrollbar-thumb {
  background: #c90d2329;
}

/* Handle on hover */
.review_scroll::-webkit-scrollbar-thumb:hover {
  background: #c90d2329;
}

/* .related_profiles .profile_img {
    background: #c7091d !important;
  } */
.related_profiles .profile_img {
  background: #fb8959 !important;
}

.white_d {
  background: white;
  padding: 50px 0 0;
}

.detailpage_contents {
  margin-bottom: 70px;
}

p.tc_d {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #4b4b4b;
  margin: 0;
}

/* .tab-c > div {
    display: none;
} */
.tab-head > div.activeTab,
.tab-c > div.activeTab {
  display: block;
}

/* .inf-_box_det {
    background: #FFFFFF;
    border: 1px solid #AEAEAE;
    padding: 25px;
    border-radius: 10px;
    margin-top: 20px;
    box-sizing: border-box;
} */
.tab-head span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.tab-head > div {
  background: #d9d9d9;
  padding: 8px 0px;
  min-width: 200px;
  text-align: center;
}

.tab-head {
  display: flex;
  align-items: center;
  gap: 12px;
}

.tab-head .activeTab {
  background: #ce2436;
  color: white !important;
}

.tab-head .activeTab span {
  background: #ce2436;
  color: white !important;
}

.tab-c .activeTab p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4b4b4b;
  margin: 0;
}

.tab-c {
  margin-top: 30px;
}

.activeTab li b {
  display: block;
}

.activeTab ul {
  padding: 0;
}

.activeTab li {
  list-style: none;
  padding: 7px 0;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.related_profiles > h2 {
  text-align: center;
}

.accout_sidebar.left-sidebar ul {
  padding: 0;
}

.accout_sidebar.left-sidebar li {
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0px 0 30px;
}

.accout_sidebar.left-sidebar li a {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

li.active-side a {
  font-weight: bold !important;
  color: black;
}

.account-info {
  width: 75%;
}

.profile-img img {
  object-fit: contain;
  max-height: 195px;
  margin-bottom: 25px;
}

.detrail-form label {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
  margin-bottom: 7px;
}

.detrail-form input,
.detrail-form textarea,
.detrail-form select {
  width: 100%;
  padding: 7px;
  box-sizing: border-box;
  border: 1px solid #949494;
  border-radius: 4px;
  font-size: 14px;
  line-height: 25px;
}

.detrail-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.form-fl.txt-fl textarea {
  min-height: 100px;
}

.form-fl.txt-fl {
  width: 100%;
}

textarea:focus {
  outline: unset;
}

.booking_block {
  display: flex;
  gap: 10px;
  box-shadow: 2px 2px 6px 2px rgb(57 57 57 / 46%);
}

.booking_block .date {
  /* background: #C70419; */
  background: #ff4f1f;
  color: white;
  font-size: 25px;
  padding: 15px;
  max-width: 19%;
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  word-spacing: 80px;
}

.img_bk img {
  width: 100%;
  height: 125px;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  background: #efefef;
  height: 100%;
  max-height: 145px;
  min-height: 145px;
  max-width: 125px;
}

.img_bk {
  width: 13%;
}

.booking_d h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 0 0 5px;
}

.booking_d span {
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  color: #000000;
}

p.time_bk {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #4b4b4b;
  padding: 0;
  margin: 7px 0;
}

button.pndng {
  background: #ffa500;
  color: #ffffff !important;
}

button.Confm {
  background: #38ae00;
  color: #ffffff !important;
}

.booking_d button {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: black;
  width: 130px;
  height: 30px;
  border: unset;
}

.bt_bk button {
  display: block;
  width: 100%;
  /* background: #ef9e9e; */
  /* background: #ff3877; */
  border: unset;
  padding: 12px;
  margin: 10px 0;
  color: #000;
  background: #d9d9d9;
}

.bt_bk button:last-child {
  /* background: #ff1959; */
  background: #d9d9d9;
}

.b_det > button {
  width: 100%;
  padding: 10px;
  border: unset;
}

.bt_bk {
  margin-left: auto;
  width: 15%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.booking_block {
  display: flex;
  gap: 10px;
  border: 1px solid #737373;
  background: white;
  margin-bottom: 20px;
}

.img_bk {
  width: 15%;
  padding: 10px 0;
  box-sizing: border-box;
}

.booking_d {
  display: flex;
  align-items: center;
}

.newsEvents {
  padding: 50px 0;
}

.newsEvents h2 {
  text-align: center;
}

p.sub_p {
  font-weight: 300;
  font-size: 22px;
  line-height: 45px;
  color: #000000;
  display: block;
  text-align: center;
  margin: 10px 0;
}

.flex_news {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

/* .box-news:nth-child(2n)  {
    height: 200px !important;
} */

.box-news {
  width: 22%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  overflow: hidden;
}

.box-news img {
  width: 100%;
  height: 388px;
  -o-object-fit: cover;
  object-fit: cover;
}

.box-news:nth-child(2) img,
.box-news:nth-child(4) img,
.box-news:nth-child(5) img,
.box-news:nth-child(7) img {
  height: 260px;
  object-position: top;
  margin: 0;
}

.box-news:nth-child(6),
.box-news:nth-child(8) {
  margin-top: -10%;
}

.news_d {
  padding: 15px;
  box-sizing: border-box;
}

.news_d h3 {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news_d p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin: 15px 0 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.menu_button {
  display: none;
}

.form-c input::placeholder {
  font-size: 12px;
}

.list_page_layout .header-main1 li a,
.DetailPage_ .header-main1 li a {
  color: #333333;
}

.form-sec {
  max-width: 100%;
}

.breadcrumbs div {
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 3px;
}

.filter_button {
  display: none;
}

.service_block {
  box-sizing: border-box;
}

.mine {
  background: white;
  padding: 25px;
  box-sizing: border-box;
}

.form-fl {
  width: 48%;
}

.bt_bk button a {
  color: #000;
}

a.router-link-exact-active.router-link-active {
  color: #ff2046 !important;
}

.signin-bt a.router-link-exact-active.router-link-active {
  color: white !important;
}

.form span {
  color: red;
  font-size: 12px;
  line-height: 30px;
}

.book_area > div {
  position: relative;
  z-index: 99;
}

.tab_head {
  display: flex;
  justify-content: center;
}

.signin.forgot.OTPs img.pos-image-auth {
  margin-right: 0;
  margin-left: auto;
}

.signin.forgot.OTPs .authform {
  margin-left: auto;
}

.signin.forgot.OTPs img.pos-image-auth {
  margin-right: 0;
  margin-left: auto;
  width: 31%;
  height: 420px;
}

button.va {
  /* background: #C70419; */
  background: #ff283e;
  cursor: pointer;
  border-radius: 4px;
  display: block;
  margin: 0 auto;
  color: white;
  font-size: 16px;
  padding: 10px 35px;
  border: unset;
  margin-top: 50px;
  min-width: 150px;
}

body.bg-scroll {
  height: 100vh;
  overflow: hidden;
}

.resend {
  text-align: center;
  font-weight: bold;
  margin-top: 25px;
}

button.tg-ps {
  position: absolute;
  background: transparent !important;
  max-width: 20px !important;
  right: 19px;
  top: 0;
  color: #c70419;
  color: #969696 !important;
  font-size: 17px !important;
  cursor: pointer;
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

span.eye {
  display: inline-block;
  width: 100%;
}

/* .signup button.tg-ps {
    height: 125%;
} */
.fl-ed {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* .edit_profile span {
    color: red;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 15px;
    cursor: pointer;
} */
.edit_profile span.save-ac {
  color: white;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 15px;
  cursor: pointer;
  background: #d2042d;
  padding: 7px 30px;
  border-radius: 5px;
}

.form-fl span {
  color: red;
  font-size: 12px;
}

.header-main2 a {
  display: block;
}

.form label .error {
  font-size: 12px;
  text-align: left !important;
  padding: 0;
  line-height: 20px;
}

.mob_f select {
  border-radius: 5px;
  border: 1px solid #949494;
}

.mob_f {
  display: flex;
  width: 100%;
  gap: 7px;
}

.regmsg {
  color: green;
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.vrfy-otp span {
  display: block;
  width: 100%;
  font-size: 20px;
  word-break: break-word;
}

button.resend {
  background: transparent;
  display: block;
  color: black;
  width: auto;
  margin: 0 auto;
  font-size: 16px;
  color: #333333;
  margin-top: 25px;
}

span.forfgotp a {
  color: #393939;
}

.vrfy-otp span {
  background: #ffdace;
  color: red;
  font-size: 13px !important;
  text-align: center;
  padding: 7px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

span.alrt_scss {
  color: green !important;
}

span.otpnot {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
  color: red;
  background: #ffbfae;
  padding: 10px;
}

button.rsnd-bt {
  background: transparent;
  margin: 0 auto;
  display: block;
  padding: 0;
  border: transparent;
  color: #ff2d47;
  font-weight: bold;
  margin-bottom: 25px;
}

.loader {
  border: 5px solid white;
  border-radius: 50%;
  border-top: 5px solid #c70419;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
  display: block;
  display: block;
  margin: 10px auto 0;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.form button {
  cursor: pointer;
}

.rec-bookrd {
  padding: 20px 0 0;
}

button.resend.noResend {
  pointer-events: none;
}

.modify_search button {
  /* background: #ff2150; */
  display: block;
  font-size: 16px;
  padding: 10px 15px;
  border: unset;
  width: 15%;
  color: white;
  background: #ff682f;
  border-radius: 4px;
  font-weight: bold;

  /*     
    background: #ff1a5d; */
}

.modify_search .form-c {
  width: 84%;
  margin: 0;
}

/* .modify_search {
    align-items: unset !important;
    justify-content: space-between;
    padding: 20px !important;
    background: #fed37d;
    margin-top: 25px;
    border-radius: 10px;
} */
.modify_search {
  align-items: unset !important;
  justify-content: space-between;
  padding: 20px !important;
  margin-top: 25px;
  border-radius: 10px;
  background-image: url(../images/boxbg.png);
  background-size: 332% 290%;
  background-repeat: no-repeat;
  background-position: center;
}

.related_profiles button.owl-dot.active span {
  background: #fb8959 !important;
}

.related_profiles button.owl-dot span {
  background: #fb895955 !important;
}

button.pndngD {
  pointer-events: none;
  opacity: 0.5;
}

.div_flex_bk {
  display: flex;
  justify-content: space-between;
}

.d_details img {
  width: 200px;
  height: 150px;
  object-fit: contain;
  object-position: left;
}

.d_details h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 5px 0;
  color: #333333;
}

.desi_d {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
}

span.q1 {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  padding: 5px 0;
}

.d_details .loc {
  padding: 10px 0 0;
  max-width: 300px;
}

.d_details .loc p {
  font-size: 14px;
}

.b_det button.Confm {
  width: 100%;
  padding: 15px;
  border: unset;
  font-size: 16px;
}

.b_det {
  min-width: 45%;
}

.details_b p span {
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
}

.banner_det span {
  text-transform: capitalize;
}

.details_b p {
  display: flex;
  /* align-items: center; */
  gap: 10px;
}

.details_b p b {
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
}

.details_b {
  margin-top: 40px;
}

.btns .full_w {
  width: 47%;
  background: #d9d9d9;
  border: unset;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}

.btns .fif {
  width: 47%;
  padding: 10px;
  background: #d9d9d9;
  border: unset;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
}

.btns {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 35px;
}

.fif.edit_b,
button.fif.edit_b a {
  background: #fe9e84;
  color: white;
}

.fif.edit_b button.fif.edit_b {
  width: 100%;
  padding: 0;
}

/* button.fif.cancel_b {
    background: #fe6c2c;
    color: white;
}
button.full_w.direc_b {
    background: #ff5671;
    color: white;
} */
.banner_det span {
  font-size: 20px;
  line-height: 33px;
}

.banner_det p {
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  margin: 5px 0;
}

.banner_det b {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
}

.banner_det * {
  color: white;
}

.banner_det {
  text-align: center;
  display: block;
  background: #ff2045;
  margin-top: 30px;
  padding: 20px 0;
}

.bk_bt {
  margin-bottom: 30px;
}

.bk_bt button {
  /* width: 30px; */
  /* height: 30px; */
  /* opacity: .5; */
  font-size: 20px;
  color: black;
  border: unset;
  background: #e4e4e4;
  border-radius: 5px;
  font-weight: bold;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 20px;
}

.account-info {
  /* background: white; */
  /* padding: 20px; */
  box-sizing: border-box;
}

.accout_sidebar.left-sidebar li a {
  cursor: pointer;
}

.accout_sidebar.left-sidebar li a:hover {
  color: #ff2046 !important;
}

button.ConfmD {
  cursor: pointer;
}

.mobile_srch_s {
  display: none;
}

.lan {
  margin-right: auto;
  display: flex;
  align-items: center;
}

.lan select {
  opacity: 0.5;
  padding: 2px;
  background-color: transparent;
  color: #a9a9a9;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

.ExperienceEducation .tab-c:last-child {
  /* background: #fad8bc; */
}

.ExperienceEducation .tab-c {
  /* background: #feef8b; */
  border: 1px solid #aeaeae;
  padding: 25px;
  border-radius: 10px;
  margin-top: 20px;
  box-sizing: border-box;
}

.ExperienceEducation .tab-c {
  background-image: url(../images/boxbg.png);
  background-size: cover;
  background-position: 44% -6px;
  background-repeat: no-repeat;
  background-size: 181% 338%;
}

/* .ExperienceEducation .tab-c {
    background-image: url(/src/assets/images/boxbg.png);
    background-size: 290% 200%;
    background-position: center;
} */
.form-c select,
.form-c input {
  background-color: #ffffff8f;
}

.modify_search.desk_top .form-c select {
  padding: 10px 28px 10px 5px;
}

.ExperienceEducation {
  display: flex;
  gap: 20px;
}

.thd {
  font-size: 22px;
  font-weight: bold;
}

/* .tab-head_date div {
    display: none;
} */
/* .tab-head_date div.activeTab {
    display: block;
} */
.tab-c_date div {
  display: none;
}

/* .tab-c_date div.activeTab {
    display: block;
} */
.tab-head_date {
  display: flex;
  gap: 10px;
  border-bottom: 3px solid #c7c7c7;
}

.tab-head_date div {
  cursor: pointer;
  padding: 7px;
  width: 33.33%;
  text-align: center;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex-wrap: wrap;
  color: #444444;
}

.tab-head_date div span {
  font-size: 14px !important;
  color: #666666;
  line-height: 30px;
}

.timeblock {
  display: block !important;
}

.tab-c_date .activeTab .timeblock:hover,
.tab-c_date .activeTab .timeblock.selectedTime {
  color: white !important;
  background: linear-gradient(0deg, #00c797, #00c797), #e2f7f2 !important;
  border: 1px solid #189e82;
}

.tab-c_date .activeTab .timeblock {
  width: 46%;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  height: 90px;
  font-size: 16px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: #e2f7f2;
  color: #fb590b;
  font-weight: bold;
  cursor: pointer;
  border-radius: 30px;
  color: #189e82;
}

.tab-c_date .activeTab .timeblock.NoTime {
  background: #fbe1e2;
  color: #b54069;
  pointer-events: none;
}

.tab-c_date div.activeTab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 25px;
  margin-top: 35px;
  margin-bottom: 25px;
}

.moreDate.activeTab label {
  margin-bottom: 10px;
  margin-top: 20px;
}

.moreDate.activeTab {
  gap: 0px !important;
}

/* .tab-c_date .activeTab .timeblock:hover {
    background: #ff6325;
    color: white;
} */
.timeblock span {
  display: block;
  font-size: 13px;
  font-weight: 300;
  line-height: 23px;
}

.timeblock div {
  width: 100%;
  display: block !important;
}

.tab-head_date .activeTab:after {
  width: 100%;
  height: 3px;
  content: "";
  display: block;
  position: absolute;
  background: #ad1457;
  bottom: -2px;
  left: 0;
}

.tab-head_date .activeTab {
  position: relative;
}

.date_tab:before {
  content: "";
  width: 100%;
  height: 2px;
  background: #666666;
  display: block;
  left: 0;
  position: relative;
  opacity: 0.5;
}

.date_tab {
  position: relative;
  width: 100%;
  margin-top: 30px;
}

.buttons_boo {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.buttons_boo button {
  padding: 10px 0;
  width: 35%;
  font-size: 17px;
  border-radius: 10px;
  border: unset;
}

.buttons_boo {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
  border-top: 1px solid #66666683;
  /* opacity: .5; */
  padding-top: 30px;
  margin-bottom: 30px;
}

button.cancel_bkn {
  background: #ff263c;
  opacity: 1;
  color: white;
}

.ExperienceEducation span {
  display: block;
}

.ExperienceEducation ul {
  padding: 0;
}

.ExperienceEducation li {
  list-style: none;
  margin: 20px 0;
}

.ExperienceEducation .tab-c * {
  color: #444444 !important;
}

.cp_banner {
  position: relative;
}

.cp_banner img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
}

.cover_bg h2 {
  color: white;
  font-size: 35px;
}

.cover_bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 98%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0000004f;
}

.inner_page_layout.common_page {
  background: white;
}

.abt_flex {
  display: flex;
}

.co_abt {
  width: 45%;
}

.abt_flex img {
  max-width: 55%;
}

button.schedule_bkng {
  background: #ff709b;
  color: white;
}
button.schedule_bkng-disable {
  background: #ff709b;
  color: white;
  opacity: 0.5;
  cursor: not-allowed;
}

.abt_flex {
  display: flex;
  margin: 50px 0;
}

.co_abt h2 {
  font-size: 35px;
  margin: 0 0 25px;
}

.co_abt p {
  font-size: 16px;
  line-height: 26px;
  color: #444444;
  margin: 0;
}

.abt_flex img {
  max-width: 35%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  margin: auto;
  margin-left: 0;
  position: relative;

  margin-left: auto;
}

.co_abt {
  width: 55%;
  position: relative;
  z-index: 5;
}

.flex_counter {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  /* margin: 100px 0 50px; */
}

.countre_box p {
  margin: 0;
  font-size: 20px;
}

.countre_box span {
  font-size: 35px;
  font-weight: bold;
}

.countre_box {
  background: white;
  width: 33%;
  padding: 50px 20px;
  box-sizing: border-box;
  text-align: center;
  margin: 30px 0;
}

.menu_footer_block li a.router-link-exact-active.router-link-active {
  color: white !important;
}

.aim_box {
  width: 50%;
  box-shadow: 2px 2px 8px 2px rgb(0 0 0 / 29%);
  padding: 25px;
  box-sizing: border-box;
  margin: 50px auto 100px;
  /* max-width: 90%; */
}

.aim_box h3 {
  font-size: 22px;
  margin: 0;
  color: #333333;
}

.aim_box p {
  font-size: 16px;
  margin: 20px 0 0;
  color: #333333;
}

/* .aims {
    margin-bottom: 100px;
} */
.contact_form {
  display: flex;
  box-shadow: 2px 2px 8px 2px rgb(0 0 0 / 29%);
  /* padding: 20px; */
  margin: 100px 0;
}

.cfo {
  width: 50%;
  margin-left: auto;
}

.sontact_info img {
  width: 35%;
  margin-left: -50px;
  height: 100%;
}

.sontact_info {
  display: flex;
  width: 50%;
}

.box_x {
  padding-top: 6%;
  margin-left: 10%;
}

.box_x h3 {
  font-size: 35px;
  margin: 0 0 20px;
}

.cbox_s a {
  font-size: 18px;
  color: #333333;
}

.cbox_s {
  display: flex;
  gap: 10px;
  font-size: 18px;
  align-items: center;
  padding: 20px 0 0 0px;
}

.cfo textarea {
  height: 100px;
}

.cfo {
  width: 50%;
  margin-left: auto;
  padding: 20px;
  box-sizing: border-box;
  background-repeat: no-repeat, repeat;
  background-image: url(../images/boxbg2.png);
  background-size: 175% 300%;
  background-size: 347% 572%;
  /* background-position: 87% 1%; */
}

.cfo label {
  width: 100%;
  display: block;
  padding: 10px 0;
  color: #555555;
  line-height: 30px;
}

.cfo input,
.cfo textarea {
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: unset;
}

.social_c a {
  width: 50px;
  font-size: 25px;
  height: 50px;
  background: #ff4133;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

.social_c {
  display: flex;
  gap: 20px;
  margin-top: 100px;
}

.sug {
  display: none;
}

.sug.showSug ul {
  margin: 0;
  padding: 0;
}

.sug.showSug li {
  display: block;
  list-style: none;
  text-align: left;
  margin: 6px 0;
}

.sug.showSug {
  display: block;
  position: fixed;
  width: 300px;
  right: 9%;
  top: 40px;
  background: #3e1620fc;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 1px 1px 4px 1px #9a9a9a;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
}

/* 
.header-top .wrapper {
    position: relative;
} */
.activeTab input,
.activeTab select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: #333333;
}

.filter-box label {
  cursor: pointer;
}

.mobile_top_form,
.showoNLYmOB {
  display: none;
}

img.careerimg {
  max-width: 28% !important;
  margin-left: auto !important;
}

.cTitle.activeAc + .cInfo {
  display: block;
  padding: 20px;
  box-sizing: border-box;
}

.cInfo {
  /* display: none; */
}

.openings > h2 {
  display: block;
  text-align: center;
  font-size: 27px;
  color: #ff5a0c;
  margin-bottom: 50px;
}

.cTitle {
  width: 100%;
  padding: 5px;
  background: #fbce7d;
  font-size: 20px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 5px;
  cursor: pointer;
  box-sizing: border-box;
}

span.infoExp {
  display: flex;
  gap: 10px;
  font-style: italic;
  margin-bottom: 10px;
}

.openings {
  margin-bottom: 100px;
}

.select_box:after {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #f00;
  position: absolute;
  top: 40%;
  right: 5px;
  content: "";
  z-index: 98;
}

.form-c select::after {
  content: "<";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 24px;
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}

select {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.8em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

span.fees_b {
  margin-top: 5%;
}

.basic_info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0;
}

.basic_info span,
.basic_info h2,
.basic_info div {
  display: block;
  width: 100%;
}

span.fees_b {
  margin-top: auto;
  margin-bottom: 4%;
}

.top_auto {
  width: 100%;
  margin-top: auto;
}

button.sumbit_c_form {
  display: block;
  width: 100%;
  padding: 10px;
  color: white;
  background: #ff2559;
  border: unset;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.ExperienceEducation .tab-c {
  background-image: url(../images/boxbg.png);
}

.detail_page_personal .loc p {
  line-height: 28px;
}

.detail_page_personal .loc {
  margin-top: 16px;
  display: flex;
  line-height: 22px;
}

span.spnsrd {
  display: inline !important;
  background: green;
  font-size: 10px !important;
  padding: 5px 10px;
  border-radius: 20px;
  color: white !important;
  margin-left: 10px;
}

.popup_date {
  display: none;
}

.popup_date.showpopup {
  position: absolute;
  display: block;
  left: 0;
  background: white;
  z-index: 9;
  width: 100%;
  top: 85px;
  box-shadow: 1px 1px 4px #666666;
  padding: 10px;
  box-sizing: border-box;
}

.showpopup .tab-head_date div {
  cursor: pointer;
  padding: 5px;
  width: 33.33%;
  min-height: 30px;
  font-size: 12px;
}

.showpopup .tab-head_date div span {
  font-size: 10px !important;
  color: #666666;
  line-height: 25px;
}

.showpopup .tab-c_date .activeTab .timeblock {
  width: 47%;
  padding: 10px;
  height: 55px;
  font-size: 12px;
  border-radius: 10px;
  color: #189e82;
}

.showpopup .tab-c_date div.activeTab {
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  max-height: 150px;
  overflow: auto;
}

.showpopup .timeblock span {
  font-size: 10px;
}

.top_auto {
  position: relative;
}

.showpopup .buttons_boo button {
  padding: 10px 0;
  width: 35%;
  font-size: 15px;
  border-radius: 10px;
  border: unset;
}

.showpopup .date_tab {
  margin-top: 0px;
}

.showpopup .date_tab:before {
  display: none;
}

.showpopup .tab-c_date .activeTab .timeblock:hover {
  color: white;
}

.showpopup .buttons_boo {
  margin-top: 15px;
  padding-top: 15px;
  margin-bottom: 10px;
}

/* width */
.showpopup .tab-c_date div.activeTab::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.showpopup .tab-c_date div.activeTab::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.showpopup .tab-c_date div.activeTab::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.showpopup .tab-c_date div.activeTab::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.showpopup .tab-c_date div.activeTab {
  padding-right: 7px;
}

.showpopup .activeTab input,
.showpopup .activeTab select {
  width: 100%;
  padding: 6px;
  font-size: 12px;
  color: #333333;
  line-height: 20px;
}

.showpopup .moreDate.activeTab label {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 12px;
}

.buttons_boo button a {
  display: block;
  color: white;
}

.Appointment_Form {
  margin: 25px 0 100px;
}

.booking_bar_info {
  display: flex;
  gap: 10px;
  background: white;
  border: 1px solid #cacaca;

  /* border-radius: 10px !important; */
}

.booking_bar_info .date {
  display: flex;
  background: #ff8467;
  font-size: 25px;
  max-width: 190px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.booking_bar_info .date {
  display: flex;
  background: #ff3c61;
  font-size: 30px;
  max-width: 190px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  word-break: break-word;
  padding: 10px;
  box-sizing: border-box;
  color: white;
  word-spacing: 80px;
}

.booking_bar_info .img_bk {
  max-width: 150px;
}

.moreDate.activeTab option span {
  font-size: 10px;
  color: red;
}

.center-loader {
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.booking_bar_info .img_bk img {
  max-height: 150px;
  min-height: 150px;
  max-width: 130px;
}

.booking_info_1 .header-main {
  background: white;
}

/* .booking_info_1 {
    background: rgba(176, 176, 176, 0.32);
} */
.booking_info_1 > .wrapper {
  padding: 50px 0;
}

.booking_bar_info .booking_d span {
  margin-bottom: 20px;
  display: block;
}

.booking_bar_info .loc {
  margin-bottom: 10px;
}

.booking_fe_f {
  margin-left: auto;
  padding-right: 30px;
}

.booking_fe_f p {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}

.booking_fe_f span {
  font-size: 25px;
  font-weight: 600;
  color: green;
  text-align: right;
  display: block;
}

.Appointment_Form {
  background: white;
  padding: 30px;
  box-sizing: border-box;
  /* box-shadow: 1px 1px 4px 4px rgb(0 0 0 / 25%); */
  box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 8%);
}

.ap_form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.ap_field label {
  font-size: 16px;
  padding-bottom: 10px;
  display: block;
}

.ap_field input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #909090;
}

.ap_field {
  width: 32%;
}

.full_button button {
  display: block;
  margin: 0 auto;
  border-radius: 5px;
  padding: 15px 10px;
  width: 35%;
  background: #ff1e51;
  color: white;
  font-weight: 600;
  font-size: 18px;
  border: unset;
}

.full_button {
  display: block;
  width: 100%;
  text-align: center;
  padding: 40px 0 10px;
}

.show_date_i svg {
  margin-left: auto;
  color: red;
  cursor: pointer;
}

.show_date_i span {
  display: block;
  font-size: 16px;
  line-height: 32px;
}

.show_date_i div {
  width: 80%;
}

.show_date_i p {
  margin: 0;
  margin-left: auto;
}

.show_date_i {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background: #ff1e5129;
  padding: 15px;
  max-width: 380px;
  margin-bottom: 40px;
  margin-top: 25px;
}

.booking_confirm_info {
  background: white;
  padding: 30px 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.booking_confirm_info h2,
.booking_confirm_info p {
  margin: 0;
}

.left_info h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 60px;
  color: #178110;
  display: flex;
  align-items: center;
  gap: 10px;
}

.left_info > span {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-top: 5px;
  display: block;
}

.info_d_c p span {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.booking_confirm_info .left_info {
  width: 50%;
}

.info_d_c i {
  font-style: normal;
}

.info_d_c p {
  font-weight: 500;
  font-size: 18px;
  line-height: 33px;
  color: #333333;
  display: flex;
  gap: 10px;
}

.info_d_c > span {
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 25px;
  display: block;
}

.info_d_c h3 {
  margin: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  color: #000000;
}

.info_d_c {
  margin-top: 30px;
}

.bk_id * {
  color: white;
}

.bk_id {
  background: #c70419;
  padding: 25px;
  width: 25%;
  box-sizing: border-box;
  position: relative;
}

span.star-pos {
  position: absolute;
  right: -13px;
  top: -10px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: green;
  border-radius: 50%;
}

.bk_id p {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  max-width: 50%;
  margin: 0 auto !important;
  display: block;
}

p.psd {
  max-width: 100% !important;
  font-size: 20px;
  font-weight: 500;
}

.bk_id div b {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 52px;
}

.bk_id div span {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 6px;
  display: block;
}

.bk_id div {
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid #c6c6c6;
  padding-top: 20px;
}

.common_space {
  margin: 50px 0;
}

.common_space p {
  font-size: 14px;
  line-height: 28px;
  margin: 0 0 15px;
}

.History_block h2 {
  margin: 0 0 20px;
  text-align: center;
  color: #3b3b3b;
  font-size: 20px;
}

.History_block {
  background: #ff7c7a52;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.History_block h3 {
  margin: 0;
  padding-bottom: 0px;
  font-size: 16px;
  color: #381a1a;
}

.History_block span {
  font-size: 12px;
  color: #555555;
}

.History_block p {
  font-size: 16px;
  color: #555555;
  margin: 10px 0px;
}

.History_block button {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  background: #ff8467;
  border: unset;
  width: 30%;
  color: white;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
}

.change_password_fm button {
  display: block;
  width: 100%;
  max-width: 500px;
}

.lb_d {
  max-width: 500px;
  margin-bottom: 0px;
}

.lb_d input {
  padding: 7px;
  border-radius: 4px;
  border: 1px solid #444444;
  margin-top: 5px;
  box-sizing: border-box;
}

.lb_d label,
.lb_d input {
  display: block;
  width: 100%;
  font-size: 16px;
}

.change_password_fm button {
  display: block;
  width: 100%;
  max-width: 500px;
  padding: 10px;
  color: white;
  background: #ff2458;
  border: unset;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
}

.fav_list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.my_title_d {
  padding-left: 0px;
  padding-bottom: 30px;
  margin: 0;
  width: 100%;
}

/* .bookings {
    padding: 0 25px;
}
.change_password .my_title_d {
    padding-left: 0;
} */
.account-info .bookings,
.account-info .History,
.fv-cov,
.change_password {
  padding: 0 10px;
}

/* .booking_bar_info .date, .booking_bar_info, .Appointment_Form {
    border-radius: 10px 0 0 10px;
} */
/* .show_date_i {
    margin-left: auto;
    margin-right: auto;
} */
/* .Appointment_Form > h2 {
    text-align: center;
} */

.provider_history {
  display: flex;
  gap: 15px;
}

.s_img_his img {
  width: 100%;
}

.s_img_his {
  width: 30%;
}

.history_info_b span {
  font-size: 14px;
  color: black;
  font-style: italic;
  padding: 5px 0;
  display: block;
}

.history_info_b h2 {
  font-size: 17px;
  margin: 0;
}

.history_info_b .st_ts {
  font-style: normal;
}

.history_info_b button.view_profile {
  max-width: 100px;
  font-size: 13px;
  padding: 5px 0;
}

.history_info_b * {
  width: 100%;
}

.history_info_b {
  display: flex;
  flex-wrap: wrap;
  max-width: 60%;
}

.history_info_b > div.tp {
  margin-bottom: 15px;
}

.provider_history {
  display: flex;
  gap: 15px;
  background: #ffffff;
  box-shadow: 2px 2px 6px 2px rgb(57 57 57 / 46%);
  /* border-radius: 10px; */
  /* align-items: center; */
  padding: 10px;
  box-sizing: border-box;
}

.History_list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.summary {
  background: white;
  display: flex;
  box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 8%);
  padding: 25px;
  box-sizing: border-box;
  gap: 50px;
  justify-content: space-between;
}

.name_sum span {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.name_sum h3 {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.name_sum .loc {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-top: 20px;
}

.name_sum .loc p {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.summary_doc_info img {
  width: 200px;
  height: auto;
  max-height: 250px;
  object-fit: cover;
  margin-bottom: 25px;
}

.patient_sum .info_line span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: hsl(0, 0%, 20%);
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.patient_sum .info_line p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #333333;
  width: 60%;
  margin: 0;
}

.info_line {
  display: flex;
  align-items: flex-start;
  gap: 25px;
  align-items: center;
  width: 100%;
}

.patient_sum .info_line {
  margin-bottom: 15px;
}

.patient_sum {
  /* margin-right: auto; */
  width: 40%;
  display: flex;
  flex-wrap: wrap;
}

.summary_doc_info {
  width: 25%;
}

.payment_sum {
  width: 30%;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
}

.info_line.pay {
  gap: 10px;
}

.info_line.pay p,
.info_line.pay span {
  margin: 0;
  width: 50%;
}

.info_line.pay p,
.info_line.pay span {
  margin: 0;
  width: 50%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.info_line.pay p {
  font-weight: 600;
}

.togglepay {
  width: 100%;
  background: #eeeeee;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: auto;
  gap: 20px;
  margin-top: 20px;
  position: relative;
}

.wsum {
  width: 100%;
  margin-bottom: auto;
}

.button_confm_sum button {
  display: block;
  padding: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  width: 50%;
  border: unset;
}

.button_confm_sum {
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  position: relative;
}

button.edit_sum {
  background: #ee5b08;
  border: unset;
}

button.cf_sum {
  background: #279102;
}

.info_line.amount_line p {
  margin: 0;
}

.info_line.amount_line {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  justify-content: space-between;
  margin-bottom: auto;
}

.toggle-sum {
  position: absolute;
  right: 20px;
  padding: 0;
  top: 12px;
  cursor: pointer;
}

.summary_doc_info,
.patient_sum {
  border-right: 1px solid #e2e2e2;
}

.kyc-box h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  color: #000000;
}

.kyc-row * {
  width: 100%;
}

.kyc-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.kyc-row input,
.kyc-row div {
  border: 1.5px solid #dddddd;
  border-radius: 9px;
  padding: 5px 10px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #000000;
}

.kyc-row div p {
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #000000;
  margin: 0;
  overflow: hidden;
  max-width: 70%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.kyc-row label {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #666666;
  font-family: "Montserrat", sans-serif;
}

.file-ip {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.file-ip svg {
  margin-left: auto;
  max-width: 25px;
  margin: 5px 0;
  margin-left: auto;
}

.file-ip input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}

.kyc-box h2 {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0 0 30px;
}

.kyc-box h2 svg {
  color: #38ae00;
}

.KYC_area .kyc-box {
  width: 100%;
}

.KYC_area {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.kyc-box h2 svg.svg-inline--fa.fa-circle-exclamation {
  color: #ffa500;
}

.KYC_area {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  max-width: 585px;
  margin: 0px auto 0px;
  padding: 50px 0 30px;
}

.bgw {
  display: block;
  background: white;
}

.kyc-box button {
  background: #c70419;
  border-radius: 9px;
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  border: 0;
  padding: 15px 0;
}

.family_Block {
  border-radius: 12.5px;
  background: #ffffff;
  box-shadow: 2.5px 2.5px 7.5px 2.5px rgba(0, 0, 0, 0.05);
  /* border: 1px solid #0000004a; */
  padding: 25px;
  box-sizing: border-box;
  width: 48%;
}

.family_area {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: space-between;
}

.fm_t {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.name_fm {
  width: 65%;
}

.name_fm h2 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 10px;
  word-break: break-word;
}

.rel {
  color: #000;
  font-size: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 20px;
}

.fm_actions button:first-child {
  color: blue;
  margin-right: 13px;
}

.fm_actions button {
  background: transparent;
  border: unset;
  text-align: center;
  color: red;
  font-size: 12.5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fm_actions {
  text-align: right;
  display: flex;
  width: 35%;
  justify-content: flex-end;
  align-items: flex-start;
}

.bf > div > span:last-child {
  max-width: 66%;
}

.fm_det_c {
  display: flex;
  flex-wrap: wrap;
  color: #000;
  font-size: 17.5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 190%;
  gap: 0px;
  justify-content: space-between;
}

.bf > div {
  display: flex;
  gap: 8px;
}

.full_fm > button {
  border-radius: 6px;
  background: #c70419;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: unset;
  padding: 10px;
  width: 100%;
  max-width: 390px;
  margin-top: 50px;
}

.full_fm > button a {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  display: block;
}

.famform {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px 25px;
}

.fields-fam {
  width: 48%;
}

.fields-fam > div {
  width: 100%;
}

.fields-fam input,
.fields-fam select {
  border-radius: 4px;
  border: 1px solid var(--grey-aa-large, #949494);
  background-color: #fff;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  /* margin-bottom: 25px; */
  margin-top: 10px;
}

.fields-fam label {
  width: 100%;
  display: block;
  color: #1a1a1a;
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fields-fam textarea {
  border-radius: 4px;
  border: 1px solid var(--grey-aa-large, #949494);
  background: #fff;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin: 10px 0 0px;
  height: 190px;
}

.adrs-fm {
  width: 100% !important;
}

.newsList .box-news img {
  width: 100%;
  height: 250px !important;
  object-fit: cover;
  object-position: center;
  border-radius: 0;
}

.newsList .box-news {
  width: 100%;
  margin: 0 !important;
  border-radius: 0;
  box-shadow: unset;
  border: 1px solid #00000029;
}

.newsList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin: 40px 0 100px;
}

.space-h2 {
  margin-top: 60px;
  font-size: 28px;
}

.newsList .news_d p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.newsList .news_d h3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.auth-d {
  display: flex;
  /* justify-content: space-between; */
  padding: 9px 0 0;
  font-size: 11px;
  color: #666666;
  font-style: italic;
  gap: 11px;
}

.auth-d span:last-child {
  font-weight: 600;
}

button.va a {
  color: white;
}

button.schedule_bkng.disabled a {
  pointer-events: none;
}

button.schedule_bkng.disabled {
  opacity: 0.7;
}

.fam_book {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.fam_book .family_Block {
  padding-left: 50px;
  position: relative;
}

.checkb > div {
  width: 100% !important;
  height: 100%;
}

.checkb {
  width: 48%;
  position: relative;
}

.checkb input {
  position: absolute;
  left: 12px;
  top: 28px;
  z-index: 9;
  color: red;
  accent-color: red;
}

.basic_info span b {
  font-weight: 400;
}

.basic_info .bs-in {
  display: flex !important;
  align-items: center;
  gap: 5px;
}

/* .checkb input {
    border:2px solid white;
    box-shadow:0 0 0 1px #932;
    appearance:none;
    border-radius:50%;
    width:12px;
    height:12px;
    background-color:#fff;
    transition:all ease-in 0.2s;
  
  }
  .checkb input :checked{
    background-color:#932;
  } */

.change_password .error {
  text-align: left;
}

.loader-placeholder {
  position: relative;
  height: 228px;
  width: 100%;
  background: rgb(227, 227, 227);
  background: linear-gradient(
    90deg,
    rgba(227, 227, 227, 1) 0%,
    rgba(182, 182, 182, 1) 7%,
    rgba(182, 182, 182, 1) 13%,
    rgba(227, 227, 227, 1) 25%
  );
  background-size: 900%;
  background-position: 100% 0%;
  z-index: 44;
  overflow: hidden;
  margin-bottom: 20px;
}

.activity {
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  background-image: linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  background-image: -moz-linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  background-image: -webkit-linear-gradient(
    to left,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.6),
    rgba(251, 251, 251, 0.3),
    rgba(251, 251, 251, 0.05)
  );
  animation: loading 1s infinite;
  z-index: 45;
}

@keyframes loading {
  0% {
    left: -45%;
  }

  100% {
    left: 100%;
  }
}

@keyframes left-right {
  0% {
    left: -30%;
  }

  50% {
    left: 100%;
  }

  100% {
    left: -45%;
  }
}

.pagination ul li.activePage {
  background: #ff2146;
  color: white;
  border-color: #ff2146;
  pointer-events: none;
}

.pagination {
  margin-top: 100px;
}

.pagination ul li:last-child,
.pagination ul li:first-child {
  width: auto;
  border: unset;
}

.pagination ul li {
  width: 25px;
  height: 25px;
  border: 1px solid #666;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}

.pagination ul {
  display: flex;
  list-style: none;
  gap: 10px;
  justify-content: center;
  padding: 0;
}

li.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.updateSuccess {
  text-align: center;
  padding: 20px;
  background: #1b5e20;
  background: #f9dce3;
  margin-bottom: 25px;
  color: white;
  position: fixed;
  /* right: 0;
    top: 5%; */
  top: 55%;
  left: 57%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  z-index: 999;
  width: 30%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-wrap: wrap;
}

.profile-img {
  position: relative;
}

.showH_Upload {
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffffab;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: none;
}

.profile-img:hover .showH_Upload {
  display: flex;
}

.showH_Upload svg {
  position: absolute;
  right: 15px;
  top: 11px;
  color: green;
}

input.hoverUplod {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.showH_Upload span {
  color: black;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 15px;
  border: 1px solid #333333;
  padding: 5px;
  border-radius: 5px;
}

b.verified-fam,
b.not-verified-fam {
  word-break: break-word;
}

.fields-fam.adrs-fm.change_password_fm {
  /* display: flex;
    align-items: center;
    justify-content: center; */
  margin-top: 30px;
  flex-wrap: wrap;
  text-align: center;
}

.fields-fam.adrs-fm.change_password_fm button {
  margin: 0 auto;
}

.fields-fam span {
  color: red;
  font-size: 12px;
  /* margin-bottom: 25px;; */
}

/* .err_space input{
    margin-bottom: 0px !important;
} */
.fields-fam > div {
  margin-bottom: 25px;
}

.full_fm .family_area {
  text-align: left;
}

.full_fm {
  text-align: center;
}

/* .fields-fam.adrs-fm.change_password_fm span {
    width: 100%;
} */
/* .profile-img {
    position: relative;
    min-height: 100px;
    min-width: 200px;
} */
.profile-img {
  position: relative;
  min-height: 100px;
  min-width: 100px;
  border-radius: 50%;
  overflow: hidden;
  width: 195px;
  height: 195px;
  margin-bottom: 25px;
}

.fields-fam.no-full_width div {
  height: 100%;
}

.fields-fam.no-full_width div textarea {
  max-height: 90%;
  height: 220px;
}

.s_img_his img {
  height: 100%;
  object-fit: cover;
}

span.file-error {
  color: red;
  font-size: 12px;
}

button.alrdy-add {
  background: #5b5b5b;
  color: white;
}

.service_block.ButtonAction button.bookNow_b {
  pointer-events: none;
}

button.now_book {
  margin: 80px auto 0;
  display: block;
  padding: 15px 10px;
  background: red;
  border: unset;
  color: white;
  font-size: 20px;
  width: 315px;
  background: #c70419;
}

.fam_book input[type="radio"] {
  padding: 0.5em;
  -webkit-appearance: none;
  outline: 0.1em solid black;
  outline-offset: 0.1em;
}

.fam_book input[type="radio"]:checked {
  display: inline-block;
  background-color: #c70419;
}

.popup_date.insideAD.showpopup > div {
  background: white;
  padding: 25px;
}

.popup_date.insideAD.showpopup {
  position: fixed;
  left: 0;
  top: 0;
  background: #0000004d;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.popup_date.insideAD.showpopup .tab-c_date div.activeTab {
  max-height: 100% !important;
  overflow: auto;
}

p.No-time {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.lrd {
  /* display: block !important; */
  width: 100%;
  min-height: 80px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.bg-white.Appointment_Form span.red {
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 15px;
  color: red;
  font-size: 14px;
}

.search_tab {
  display: none;
}

.form-c {
  justify-content: center;
}

.form-c *:nth-child(7) {
  width: 98%;
}

.modify_search.desk_top .form-c select,
.modify_search.desk_top .form-c input {
  width: 15% !important;
}

.modify_search.desk_top .form-c {
  justify-content: flex-start;
}

/* .detaile_booking .d_details img {
    object-fit: cover;
} */

.popup_date.insideAD.showpopup > div {
  width: 50%;
}

button.edit_sum a {
  color: white;
}

.center-loader-booking {
  background: #eeeeee;
  border: 1px solid #cacaca;
  min-height: 175px;
  max-height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-placeholder.center-loader-address {
  height: 350px;
  background: #eeeeee;
  border: 1px solid #cacaca;
}

.pagination.booking {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bk_id p {
  word-break: break-word;
  max-width: 66%;
}

.cInfo button {
  font-size: 14px;
  padding: 10px 25px;
  background: black;
  color: white;
  border: unset;
  margin-top: 25px;
}

.applyForm {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0000007d;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.applyForm form {
  width: 90%;
  max-width: 500px;
  background: white;
  padding: 25px;
  box-sizing: border-box;
  /* display: flex;
    flex-wrap: wrap; */
  gap: 0px;
  position: relative;
  height: auto;
  /* overflow-x: auto; */
}

form .field_career {
  margin: 15px 0;
}

.field_career {
  width: 100%;
}

.field_career input,
.field_career textarea,
.field_career select {
  display: block;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 25px;
}

.field_career label {
  display: block;
  margin-bottom: 5px;
}

.field_career button {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: #821023;
  border: unset;
  color: white;
}

.applyForm.show_form {
  display: flex;
}

button#close_cf {
  position: absolute;
  right: -5px;
  top: -5px;
  background: black;
  width: 35px;
  height: 35px;
  color: white;
  border-radius: 50%;
  border: unset;
}

.err_space {
  position: relative;
}

.fields-fam span {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -20px;
}

.fields-fam .loader {
  position: unset;
  width: 15px !important;
}

.layout-sec {
  position: relative;
}

.filter-box li p {
  margin-left: 10px;
  font-weight: 500;
}

.fields-fam > div span.error {
  text-align: left !important;
}

.fields-fam > div {
  position: relative;
}

.address-f span.error {
  bottom: 0px;
}

/* .signin-form-lert  span.alrt_scss {
    position: absolute;
    width: 100%;
    min-height: 10%;
    left: 0;
    top: -28px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cef8ce;
} */
/* .signin-form-lert span.alrt_scss {
    position: absolute;
    width: 100%;
    height: 96%;
    left: 0;
    top: 8px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.kyc-row span.error {
  text-align: left;
  padding: 0;
}

a.show-hide-img.image {
  width: 50%;
}

a.show-hide-img.image img {
  width: 100%;
  max-height: 200px;
  object-fit: fill;
}

.contact_form .cfo label {
  position: relative;
}

.contact_form span.error {
  position: absolute;
  left: 0;
  bottom: -15px;
  text-align: left !important;
  font-size: 10px;
  color: #b72a2a;
}

.bf {
  width: 100%;
}

/* .bf > div > span:first-child {
    width: 115px;
} */
.bf > div > span:first-child b {
  font-weight: 400;
  margin-right: 15px;
}

.bf > div > span:first-child {
  width: 115px;
  display: flex;
  justify-content: space-between;
}

.ExperienceEducation .tab-c {
  width: 50%;
}

.store-ply {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.store-ply img {
  max-width: 130px;
}

.drp-dwn {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  width: 200px;
  padding: 12px;
  display: none;
  z-index: 99;
}

.signin-bt.dropdownCls {
  position: relative;
}

.drp-dwn a.router-link-exact-active.router-link-active {
  color: black !important;
}

.drp-dwn a {
  background: unset;
  background-color: white !important;
  color: black !important;
  font-weight: 400;
  text-align: left !important;
  font-size: 13px;
  width: 100%;
  display: block;
  padding: 0;
  height: auto;
  padding: 3px;
  margin: 5px 0;
}

.privcyPolicy404 h2 {
  text-align: center;
}

.privcyPolicy404 {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmark-circle {
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.checkmark-circle .background {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: green;
  position: absolute;
}

.checkmark-circle .checkmark {
  border-radius: 5px;
}

.checkmark-circle .checkmark.draw:after {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.checkmark-circle .checkmark:after {
  opacity: 1;
  height: 13px;
  width: 11.5px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 5px solid white;
  border-top: 5px solid white;
  border-radius: 2.5px !important;
  content: "";
  left: 11px;
  top: 26px;
  position: absolute;
}

@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: 11px;
    opacity: 1;
  }

  40% {
    height: 23px;
    width: 11px;
    opacity: 1;
  }

  100% {
    height: 23px;
    width: 11px;
    opacity: 1;
  }
}

@-moz-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: 11px;
    opacity: 1;
  }

  40% {
    height: 23px;
    width: 11px;
    opacity: 1;
  }

  100% {
    height: 23px;
    width: 11px;
    opacity: 1;
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: 11px;
    opacity: 1;
  }

  40% {
    height: 23px;
    width: 11px;
    opacity: 1;
  }

  100% {
    height: 23px;
    width: 11px;
    opacity: 1;
  }
}

/* .vrfy-otp.success-popUpm.popup-login > div {
    background-color: #ffdace;
} */
.change_password .error {
  text-align: left !important;
  padding: 5px 0 0 0 !important;
}

.hideOnWeb {
  display: none !important;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-30 15:58:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.popup-login {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #00000047;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: slide-in-fwd-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.popup-login > div {
  width: 30%;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  /* height: 130px; */
  align-items: center;
  justify-content: center;
  /* max-width: 300px; */
  padding: 25px 0;
  border-radius: 10px;
}

.popup-login h3 {
  width: 100%;
  text-align: center;
  color: black;
  font-size: 16px;
}

.noac {
  text-align: center;
}

.pagination.booking ul {
  padding: 0;
}

.comment-block p {
  height: 120px;
  overflow: auto;
  margin: 0;
}

.comment-block p::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.comment-block p::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.comment-block p::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.comment-block p::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.booking_date_det h2 {
  font-size: 22px;
}

img.newsIm {
  max-width: 100%;
  max-height: 440px;
  object-fit: contain;
  margin-top: 20px;
}

.btns .fif:hover {
  background: #9e9d9d;
  color: white;
}

.bt_bk button:hover {
  background: #9e9d9d;
  color: white;
}

.pos-loadr {
  position: relative;
}

.pos-loadr .loader {
  position: absolute;
  right: 23px;
  bottom: 13px;
  border: 5px solid #d9d9d900;
  border-top: 4px solid #c70419;
}

button.pos-but-loader .loader {
  position: absolute;
  right: 42%;
  top: 0;
  border: 3px solid #ffffff00;
  border-top: 3px solid #ebebeb;
}

button.pos-but-loader {
  position: relative;
}

.modify_search button.pos-but-loader .loader {
  right: 19%;
  top: 3px;
}

.modify_search button.pos-but-loader:hover {
  opacity: 0.8;
}

.modify_search input {
  box-sizing: border-box;
}

.modify_search .form-c {
  align-items: unset;
}

.comment-block p img {
  float: right;
  width: 80px !important;
  height: 80px;
  border-radius: 50%;
  margin-left: 15px;
  margin-bottom: 15px;
}

.single-news h3 {
  font-size: 28px;
  -webkit-line-clamp: 50;
  line-height: 1.23;
  word-break: break-word;
}

.co-news.single-det-news {
  margin-top: 25px;
}

.field_career .error {
  text-align: left !important;
  padding: 0;
}

.profile_det {
  position: relative;
  z-index: 0;
}

a.position-route {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.noac a {
  color: red;
}

.detrail-form label b {
  color: red;
}

.prefix {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.prefix span {
  position: absolute;
  left: 10px;
  font-size: 14px;
  opacity: 0.7;
  color: black;
  width: auto;
  height: auto !important;
  bottom: unset;
}

.prefix input {
  padding-left: 40px !important;
}

.prefix.new-fm-prefix span {
  top: 21px;
  font-size: 13px;
}

.signinPrefix span {
  font-size: 13px;
}

.hideOnlod span {
  display: none;
}

.fields-fam label b {
  /* margin-left: auto;
    display: inline-block;
    float: right; */
  font-size: 13px;
  color: #e0104b;
  font-weight: 500;
}

/* .fields-fam label {
    display: flex;
    align-items: center;
} */
b.verified-fam {
  font-size: 12px;
  margin-left: auto;
  display: block;
  line-height: 1;
  color: green;
  font-weight: 500;
}

b.not-verified-fam {
  color: #00bcd4;
  font-weight: 500;
  font-size: 12px;
  display: block;
  line-height: 1;
  cursor: pointer;
}

.hidehere {
  display: none !important;
}

.hidehere + .account-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-block p {
  margin: 0;
}

.notification-block h3 {
  margin: 0 0 15px;
}

.notification-block {
  background: white;
  padding: 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  gap: 15px;
  box-shadow: 2px 2px 6px 2px rgb(57 57 57 / 46%);
}

.notification-block > div:first-child {
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e8175d;
  background: #f1287614;
  font-size: 40px;
}

.notification-block > div:last-child {
  max-width: 90%;
}

.loc.exp {
  margin-bottom: 10px;
}

.yN button:last-child {
  background: red;
  border-color: red;
  color: white;
  box-shadow: unset;
}

.yN button {
  padding: 8px 20px;
  border: unset;
  background: grey;
  color: white;
  border-radius: 8px;
}

.yN {
  display: flex;
  gap: 10px;
}

.updateSuccess.popup-login p {
  color: black;
  font-size: 15px;
  width: 100%;
}

.opacityCls {
  opacity: 0.5;
}

h2.soryText {
  font-size: 20px;
  padding-top: 50px;
  min-height: 30vh;
  word-break: break-word;
}

span.imageError {
  color: red;
  font-size: 13px;
  margin-bottom: 15px;
  display: block;
  word-break: break-word;
}

.noweb {
  display: none !important;
}

body.bg-scroll.blurbg:after {
  /* background: black; */
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #00000070;
  z-index: 9;
}

span.error.ter {
  margin-bottom: 10px;
  margin-top: -23px;
  word-break: break-word;
  font-size: 13px;
}

a.bcktoHome.router-link-active {
  padding: 7px;
  display: block;
  max-width: 110px;
  background: #9b9b9b;
  margin-bottom: 10px;
  font-size: 12px;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.checkb .fm_actions button:last-child {
  display: none;
}

.fav_list .service_block {
  box-shadow: 2px 2px 6px 2px rgb(57 57 57 / 46%);
}

.error {
  word-break: break-word;
}

button.pos-loadr.Cancel-bkng-ldr .loader {
  border: 2px solid #d9d9d900;
  border-top: 2px solid #ffffff;
  width: 10px;
  right: 6px;
  height: 10px;
}

.updateSuccess.popup-login p {
  word-break: break-word;
}

.popup-login h3 {
  word-break: break-word;
}

/* home loged out dropdown button */
.drop-down-main .dropdown-menu {
  background-color: #fff;
  border-radius: 0;
  width: 200px;
  border: unset;
}

.dropdown button {
  background: linear-gradient(90deg, #ff1e56, #ff4765 54%, #ff1a5a);
  color: #f0f0f0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  font-weight: 500;
  font-size: 15px;
  padding: 0 10px;
  border: none;
  border-radius: 8px;
}

.drop-down-main .dropdown-item {
  padding-bottom: 8px;
}

.drop-down-main .dropdown-menu ul li a {
  font-size: 15px !important;
  line-height: 24px !important;
  font-family: Poppins, sans-serif !important;
  font-weight: 400 !important;
  text-align: left !important;
  font-size: 13px !important;
  width: 100% !important;
  display: block !important;
  padding: 0 !important;
  height: auto !important;
  padding: 3px !important;
  margin: 5px 0 !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: unset;
}

.drp-dwn .myli {
  background: unset;
  background-color: #fff !important;
  font-weight: 400;
  text-align: left !important;
  font-size: 13px;
  width: 100%;
  display: block;
  padding: 0;
  height: auto;
  padding: 3px;
  margin: 5px 0;
}

/* ......................... */
.error_con {
  color: red;
  font-size: 14px;
  width: 100%;
}

/* ....accoredian........... */
.accordion-button:not(.collapsed) {
  background-color: #fbce7d !important;
}

.accordion {
  --bs-accordion-bg: #fbce7d !important;
  padding: 10px !important;
}

.accordion-body {
  background-color: white !important;
}

.accordion-body {
  margin-top: 10px !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-item {
  margin-top: 5px !important;
}

/* ................new styles..................... */
.css-tj5bde-Svg {
  height: 15px !important;
  width: 15px !important;
  color: #333333 !important;
}

.css-1jqq78o-placeholder {
  color: #333333 !important;
  font-size: 10px !important;
}

.css-t3ipsp-control:hover {
  border-color: #949494 !important;
  border-radius: 4px !important;
}

.css-t3ipsp-control {
  border-color: #949494 !important;
  box-shadow: none !important;
  border-radius: 4px !important;
}

.css-13cymwt-control {
  border-color: #949494 !important;
  border-radius: 4px !important;
}

.react-datepicker__view-calendar-icon input {
  padding: 9px 65px 9px 30px !important;
  font-size: 10px !important;
  color: #333333 !important;
  border-color: #949494 !important;
  border-radius: 4px !important;
  border-width: 1px !important;
  width: 100% !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__navigation {
  background-color: transparent !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  color: #333333 !important;
}

.banner_head {
  display: block !important;
  font-size: 2em !important;
  margin-block-start: 0.67em !important;
  margin-block-end: 0.67em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  font-weight: bold !important;
}

.activeShow {
  display: block !important;
}

/* /............search form........../ */
.css-1dimb5e-singleValue {
  font-size: 12px !important;
}

.searchform_distance {
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  border-color: #949494 !important;
  border-width: 1px;
  font-size: 10px;
}
.bookings_top .wrapper .react-multi-carousel-item {
  width: 400px !important;
}
.agreeterms {
  display: flex;
  justify-content: flex-start;
}
#agree {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  margin-right: 10px;
  color: red;
}
input[type="checkbox"]:checked::before {
  content: "\2713"; /* Unicode character for checkmark */
  display: block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: red; /* Change to red */
  border: 2px solid red; /* Change border color to red */
  border-radius: 5px;
  color: white;
}
.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
  line-height: 1 !important;
  opacity: 1 !important;
  color: #e4e1e1 !important;
}
.slick-prev {
  left: 0 !important;
}
.slick-next {
  right: 0 !important;
}
.listing-service-main {
  background: #e9eaeb;
}
.my-account.padding-change .textfield-row-main div div {
  width: 100% !important;
}
.category-listing .slick-prev {
  z-index: 999 !important;
}
.slick-track {
    webkit-transition: none;
}
