.bookingdetail-main {
  padding: 30px 0;
}

.bookingdetail-main {
  border-radius: 10px;
  background: #fff;
  padding: 30px 20px;
  box-shadow: 2px 2px 25px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.booking-detail-main {
  border-radius: 10px;
  background: #fff;
  padding: 50px 30px;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
}

.booking-detail-inner {
  /* height: 680px; */
  overflow-y: scroll;
  padding: 10px;
  max-height: 765px;
}

.booking-detail-inner::-webkit-scrollbar-track {
  /* border: 1px solid #000; */
  padding: 2px 0;
  /* background-color: #404040; */
}

.booking-detail-inner::-webkit-scrollbar {
  width: 3px;
}

.booking-detail-inner::-webkit-scrollbar-thumb {
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  /* background-color: #737272; */
  /* border: 1px solid #000; */
}

.booking-detail-main h2 {
  font-weight: 500;
  color: #333333;
  font-size: 26px;
  margin-bottom: 50px;
  font-family: Poppins, sans-serif;
}

.booking-detail-main img {
  border-radius: 20px;
  display: block;
  width: 100%;
  height: auto;
  height: 100%;
  object-fit: cover;
}

.align-items h4 {
  text-align: right;
  font-size: 18px;
  color: #414141;
}

.align-items h5 {
  text-align: right;
  font-size: 18px;
  color: #298532;
}

.badge-2 {
  padding: 10px 20px;
  color: #302f2f;
  font-size: 12px;
  background: #ffdbe3;
  float: right;
  margin-top: 40px;
  font-weight: 500;
}

.booking-badge {
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  background: #ffdbe3;
  width: 100%;
  display: block;
}

.booking-badge h5 {
  color: #414141;
  font-size: 14px;
}

.booking-badge p {
  color: #838181;
  font-size: 12px;
  margin-bottom: 2px;
}

.edit-button {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 8px;
  justify-content: space-between;
  background: transparent;
  color: #ce2335;
  border: 1px solid #ce2335;
  font-size: 14px;
}

.create-form-inner>div {
  width: 100%;
}

.create-form-inner {
  margin-bottom: 15px;
}

.create-form-inner div div div {
  width: 100%;
}

.margin-change>div {
  margin: 0;
}

.margin-change div div div:focus {
  background: transparent;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0 !important;
}

.field-parent.datepicker fieldset {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.field-parent.datepicker input {
  padding-left: 0;
  padding-bottom: 2px;
}

.field-parent.datepicker label {
  left: -13px;
}

.field-parent.datepicker svg {
  position: absolute;
  right: -155px;
  width: 18px;
  bottom: -12px;
}

.addmemeber {
  background: #D2042D;
  color: #FFF;
  font-size: 18px;
  border: 1px solid #D2042D;
  padding: 8px 40px;
  border-radius: 10px;
  width: 50%;
  margin: 40px auto 10px;
  display: block;
}

.addmemeber:hover {
  background: transparent;
  color: #D2042D;
  border: 1px solid #D2042D;
  outline: 0;
}

.booking-member {
  border: 1px solid #D2042D;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 15px;
}

.booking-member:last-child {
  margin-bottom: 0;
}

.description-main {
  font-size: 14px;
  font-weight: 500;
  color: #414141;
  word-break: break-all;
  margin-bottom: 10px;
}

.person-details {
  margin-bottom: 10px;
}

.background-checkbox {
  width: 100%;
  height: 100%;
  background-color: #eee;
}

.containerr input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border-radius: 50%;
  transform: translate(-50%);
  border: 2px solid #D2042D;
}

.containerr input:checked~.checkmark {
  background-color: #fff;
  border: none;
  border: 2px solid #D2042D;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerr input:checked~.checkmark:after {
  display: block;
}

.containerr .checkmark:after {
  top: 2px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #D2042D;
  transform: translateX(-50%);
}

.containerr {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-top: 3px;
}

.person-details h3 {
  font-size: 16px;
  color: #D2042D;
  margin-bottom: 0;
}

/* Tab style */
.button-main .react-tabs_tab-panel.react-tabs_tab-panel--selected {
  padding: 10px 20px;
}

.button-main ul {
  background: #d72045 !important;
  text-align: center !important;
}

.button-main ul li {
  width: 33.33%;
}

.button-main ul li {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  font-family: Montserrat;
  line-height: normal;
}

.button-main ul li::after {
  width: 33.33%;
  left: 15px !important;
}

.button-main ul li p {
  margin-bottom: 0;
  font-size: 12px;
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-family: Montserrat;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 2px;
}

.button-main .react-tabs__tab--selected {
  background: unset;
  border-color: #fff;
  border-bottom: 3px solid #fff;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
}

.button-main .modal-dialog {
  position: absolute !important;
  width: 100% !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0;
}

.button-main .modal-content {
  border: unset;
}

.availblae-active-disactive {
  padding: 15px;
  color: #B54069;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  border-radius: 20px;
  background: #FBE1E2;
  margin-bottom: 25px;
  text-align: center;
}

.availblae-active {
  padding: 15px;
  color: #189E82;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  border-radius: 20px;
  background: #E2F7F2;
  margin-bottom: 10px;
  text-align: center;
}

.availblae {
  padding: 15px;
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  border-radius: 20px;
  background: linear-gradient(0deg, #00C797 0%, #00C797 100%), #E2F7F2;
  margin-bottom: 10px;
  text-align: center;
}

.button-main .react-calendar {
  position: absolute;
  z-index: 999;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
  width: 280px;
}

.button-main .react-calendar__tile {
  padding: 5px;
}

/* Tab style : end*/
.large-2 {
  height: 250px;
  overflow-y: scroll;
}

.large-2::-webkit-scrollbar-track {
  /* border: 1px solid #000; */
  padding: 2px 0;
  /* background-color: #404040; */
}

.large-2::-webkit-scrollbar {
  width: 3px;
}

.large-2::-webkit-scrollbar-thumb {
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  /* background-color: #737272; */
  /* border: 1px solid #000; */
}
.errorDetails {
  color: red;
  font-size: 11px;
}
.addNew{
  background: grey;
  color: #FFF;
  font-size: 18px;
  border: 1px solid #D2042D;
  padding: 8px 40px;
  border-radius: 10px;
  width: 50%;
  margin: 26px auto 10px;
  display: block;
}
.field-parent.datepicker .MuiStack-root.css-160unip {
  overflow: hidden !important;
}
.field-parent.datepicker fieldset {
  border-radius: 0 !important;
}
.field-parent.datepicker .css-1bn53lx {
  padding-bottom: 3px !important;
}
.field-parent.datepicker svg {
  right: -188px!important;
}
.css-165mck1{
  overflow: hidden!important;
}