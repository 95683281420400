.detail-main {
    padding: 50px 0;
}

.wrapper {
    width: 1200px;
    margin: 0 auto;
    max-width: 90%;
}

.mainP.HomePage {
    background: #f1f3f4;
}

.profile-individual {
    border-radius: 10px;
    background: #FFF;
    padding: 20px;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.10);
}

.image_round {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border: 1px solid #e7e6e6;
    border-radius: 50%;
    position: relative;
    margin: auto;
}

.image_round .img {
    width: 90%;
    height: 90%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
    -o-object-position: center;
    object-position: center;
}

.image_round .tick-svg {
    color: #59b153 !important;
    position: absolute !important;
    bottom: -16px !important;
    background: #fff !important;
    border-radius: 50% !important;
    height: 40px !important;
    width: 40px !important;
    left: 50% !important;
    transform: translateX(-50%);
}

.round-info h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #333;
    text-align: left;
    display: block;
    margin: 10px 0 2px;
    font-family: Montserrat, sans-serif;
    padding: 10px 0 0;
}

.round-info p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #333;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-items: center;
    margin: 0;
}

.locationdetail {
    margin-top: 5px;
    display: flex;
    line-height: 22px;
}

.locationdetail p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #333;
    margin: 0;
    max-width: 90%;
    word-break: break-word;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-items: center;
    margin: 0;
}

.detail-map {
    height: auto;
    width: 100%;
    margin: 20px 0 0;
}

.user-wishlist {
    background: #d72045;
    color: #fff !important;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    border: none;
    width: 80%;
    border: 1px solid #d72045;
}

.user-wishlist:hover {
    border: 1px solid #d72045;
    color: #d72045 !important;
    transition: 0.3s;
    background: transparent;
}

.user-remwishlist {
    background: transparent;
    color: #d72045 !important;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    border: none;
    width: 80%;
    border: 1px solid #d72045;
}

.user-remwishlist:hover {
    border: 1px solid #d72045;
    color: #fff !important;
    transition: 0.3s;
    background: #d72045;
}

.profile-individual h2 {
    font-size: 22px;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    margin-bottom: 0px;
}

.rate {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #d72045;
    margin: 10px 0 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f1f1f1;
}

.profile-individual .react-tabs__tab-list {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 20px;
}

.profile-individual .react-tabs {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    text-align: center;
    color: #333;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4;
    text-transform: capitalize;
}

.profile-individual .react-tabs__tab--selected {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #d72045;
}

.profile-individual .react-tabs__tab--selected::after {
    border-bottom: 1px solid #d72045;
    top: 92%;
}

.profile-individual .react-tabs li {
    width: 33.33%;
}

.profile-individual .react-tabs span {
    text-align: center;
    color: #333;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.6;
    text-transform: capitalize;
}

.availblae-active-checktime {
    padding: 15px;
    color: black;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8;
    border-radius: 20px;
    background: #bcbaba;
    margin-bottom: 10px;
    text-align: center;
}

.availblae-active-disactive {
    padding: 15px;
    color: #B54069;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8;
    border-radius: 20px;
    background: #FBE1E2;
    margin-bottom: 10px;
    text-align: center;
}

.availblae-active {
    padding: 15px;
    color: #189E82;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8;
    border-radius: 20px;
    background: #E2F7F2;
    margin-bottom: 10px;
    text-align: center;
}

.availblae {
    padding: 15px;
    color: #fff;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8;
    border-radius: 20px;
    background: linear-gradient(0deg, #00C797 0%, #00C797 100%), #E2F7F2;
    margin-bottom: 10px;
    text-align: center;
}

.buttons_boo {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 30px;
    border-top: 1px solid #f1f1f1;
    padding-top: 30px;
    margin-bottom: 30px;
}

button.cancel_bkn {
    background: #ff263c;
    opacity: 1;
    color: #fff;
}

.buttons_boo button {
    padding: 10px 0;
    width: 35%;
    font-size: 16px;
    border-radius: 10px;
    border: unset;
}

button.schedule_bkng {
    background: #ff709b;
    color: #fff;
}

.buttons_boo button {
    padding: 10px 0;
    width: 35%;
    font-size: 16px;
    border-radius: 10px;
    border: unset;
}

.buttons_boo button a {
    color: #fff;
}

p.tc_d {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #4b4b4b;
    margin: 0;
    text-align: left;
}

.moreDate.activeTab label {
    width: 100%;
    text-align: left;
    padding: 15px 0;
}

.activeTab input,
.activeTab select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    color: #333;
}

.related_profiles {
    margin: 50px 0 40px;
}

.related_profiles h2 {
    font-size: 26px;
    text-align: center;
}

.single_profile {
    display: flex;
    align-items: center;
}

.related_profiles .profile_img {
    background: #d34306 !important;
}

.profile_img {
    width: 120px;
    height: 120px;
    overflow: hidden;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: -58px;
    border: 1px solid #dddddd;
    position: relative;
    z-index: 99;
}

a.position-route {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.profile_det {
    border: 1px solid #ddd;
    padding: 15px;
    max-width: 325px;
    padding-left: 75px;
    box-sizing: border-box;
    padding-right: 20px;
    background: #fff;
    width: 100%;
    border-radius: 5px;
}

.profile_det h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    font-family: Montserrat;
    color: #333;
    margin: 0;
}

.profile_det span {
    font-weight: 300;
    font-family: Montserrat;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 0;
    color: #4b4b4b;
}

p.exp_adrsp {
    font-weight: 700;
    word-break: break-word;
    margin-bottom: 4px;
}

p.shrt-des {
    margin-bottom: 4px;
    font-size: 13px;
}

.profile_img img {
    display: block;
    width: 100%;
}

.detail-main .react-multi-carousel-item {
    width: 400px !important;
    padding: 0 10px;
}

.detail-main .react-multi-carousel-dot-list {
    position: static;
    margin: 10px 0;
}

.react-multi-carousel-dot-list {
    position: static;
}

.react-multi-carousel-item {
    width: 165px !important;
    padding: 0 10px;
    max-width: 500px !important;
}

/* .box-news img {
    width: 100%!important;
    height: 150px!important;
    object-fit: cover!important;
    object-position: top!important;
} */
.banner {
    margin-bottom: 20px;
}

/* .box-news {
    width:100%!important;
    border-radius: 15px!important;
} */

.detail-main .react-multi-carousel-dot button {
    width: 8px;
    height: 8px;
    border-width: 0px;
    background: #f1c6c6;
}

.detail-main .react-multi-carousel-dot--active button {
    background: #d34306;
}

.calender-position {
    position: absolute;
    top: 396px;
    right: 200px;
    z-index: 9999999;
}

@media (max-width: 991px) {
    .calender-position {
        position: absolute;
        top: 1000px;
        right: 38px;
        z-index: 9999999;
    }
}