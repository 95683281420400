.favorates-main {
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
.my-accountt h2 {
  font-size: 26px;
  font-weight: 600;
}
.favorates-main img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.basic_info div {
  display: block;
  width: 100%;
}
.basic_info h2 {
  font-size: 20px;
  line-height: 30px;
  color: #000;
  font-family: Poppins, sans-serif;
  margin: 0;
  margin-bottom: 5px;
  word-break: break-all;
}
.basic_info span {
  font-size: 16px;
  line-height: 24px;
  color: #3e0000;
}
.top_auto {
  position: relative;
}
.loc {
  display: flex;
  gap: 10px;
  color: #4b4b4e;
  align-items: flex-start;
}
.loc svg {
  margin-top: 5px;
  color: #4b4b4b;
}
.loc p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #4b4b4b;
  margin: 0;
  max-width: 90%;
  word-break: break-word;
}
button.view_profile {
  background: #ff6b35;
  color: #fff;
  border: unset;
  padding: 8px 20px;
  margin-top: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  width: 100%;
  padding: 10px;
  margin-top: 0;
}
.booking_info_b {
  width:unset;
  margin-left: auto;
  padding-top: 5px;
  padding-left: 0;
}
.basic_info span {
  font-size: 15px;
}
.loc {
  display: flex;
  gap: 10px;
  color: #4b4b4e;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}
button.bookNow_b {
  background: #ff6057;
  font-weight: 500;
  color: #fff;
  border: unset;
  width:100%;
  padding: 8px;
  margin-bottom: 0;
  border-radius: 10px;
}
@media (min-width: 992px) and (max-width: 1024px) {
    .favorates-main .booking_info_b .loc {
      margin-bottom: 74px !important;
    }
  }

@media (max-width: 991px) {
  .favorates-main .basic_info {
    margin-top: 10px;
    border-right: 0;
  }
  .favorates-main .booking_info_b {
    margin-top: 10px;
  }

  button.bookNow_b {
    padding: 10px;
  }
}
@media (max-width: 575px) {
    .favorates-main .booking_info_b .loc {
        margin-bottom: 74px !important;
      }
}
