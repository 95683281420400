.bookigs-details-single {
    border-radius: 10px;
    background: #fff;
    padding: 20px;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}
.individual-img img {
    border-radius: 10px;
    width: 100%;
    height: auto;
    background: #fff;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 5px;
}

.bookigs-details-single>.row {
    align-items: center;
}

.bookigs-details-single h3 {
    font-weight: 500;
    font-size: 22px;
    line-height: normal;
    color: #000;
    margin: 0 0 10px;
}

.bookigs-details-single span {
    font-weight: 500;
    font-size: 16px;
    line-height: normal;
    color: #4b4b4b;
    margin-bottom: 10px;
}

p.time_bk {
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: #4b4b4b;
    padding: 0;
    margin: 7px 0 10px;
}

.bookigs-details-single .details {
    width: 100%;
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    border: 1px solid #d2042d;
    padding: 5px 0;
    background: #d2042d;
    border-radius: 8px;
}
.bookigs-details-single .reschedule {
    width: 100%;
    margin-bottom: 15px;
    color: #d2042d;
    font-size: 16px;
    border: 1px solid #d2042d;
    padding: 5px 0;
    background: #fff;
    border-radius: 8px;
}
.bookigs-details-single .approved {
    width: 27%;
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    border: 1px solid #38ae00;
    padding: 1px 0;
    background: #38ae00;
    border-radius: 8px;
}
.bookigs-details-single .notapproved {
    width: 27%;
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    border: 1px solid #f19a20;
    padding: 1px 0;
    background: #f19a20;
    border-radius: 8px;
}
.badge{
    border: 1px solid #38ae00;
    padding: 5px;
    width: 50%!important;
    background: #38ae00;
    font-size: 12px;
    border-radius: 8px;
    color: #fff;
    line-height: 1.3;
    float: left;
    margin-top: -35px;
}
.notapprbadge{
    border: 1px solid #f19a20;
    padding: 5px;
    width: 50%!important;
    background: #f19a20;
    font-size: 12px;
    border-radius: 8px;
    color: #fff;
    line-height: 1.3;
    float: left;
    margin-top: -35px;
}
.back{
    color:#4b4b4b;
    border: unset;
    background: #e4e4e4;
    border-radius: 5px;
    font-weight: 700;
    font-weight: 500;
    font-size: 14px;
    padding: 5px 20px;
    float: right;
}