.history-inner{
    background: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    height: 180px !important;
}
.history_info_b h2 {
    font-size: 16px;
    margin: 0;
    word-break: break-all;
}
.history_info_b>div.tp {
    margin-bottom: 15px;
}
.history_info_b span {
    font-size: 13px;
    color: #000;
    font-style: italic;
    padding: 5px 0;
    display: block;
    word-break: break-all;
}
.history_info_b .st_ts {
    font-style: normal;
}
button.view_profile {
    background: #ff6b35;
    color: #fff;
    border: unset;
    padding: 8px 20px;
    margin-top: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    padding: 6px;
    border-radius: 8px;
}