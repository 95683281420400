/* header */
a {
  text-decoration: none;
}
.header-top {
  background: #000;
  text-align: right;
}
.wrapper {
  width: 1280px;
  margin: 0 auto;
  max-width: 90%;
}
.top-box-header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 5px;
  height: 40px;
}

.top-box-header * {
  color: #fff;
  font-size: 12px;
}

.top-box-header.search-bar {
  border: unset;
}

.top-box-header.search-bar input {
  background: transparent;
  border: unset;
}

.header-top .wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.mobile_top_form,
.showoNLYmOB {
  display: none;
}

.top-box-header a {
  text-decoration: none;
}

.list_page_layout .menu {
  background: #fff;
}

.menu {
  padding: 10px 0;
}

.menu-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-mainMenu2 a {
  display: block;
}

.header-mainMenu {
  display: flex;
  align-items: center;
  gap: 35px;
}

.header-mainMenu .main_menu_list_ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin-bottom: 0;
}

.DetailPage_ .header-mainMenu li a,
.list_page_layout .header-mainMenu li a {
  color: #333;
}

.list_page_layout .header-mainMenu li a {
  color: #333 ;
}

.header-mainMenu2 img {
  max-width: 240px;
}

.signin-bt.dropdownCls {
  position: relative;
}

.header-mainMenu a {
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #333;
  gap: 7px;
}

.signin-bt a {
  background: #d72045;
  color: #fff !important;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  padding: 0 10px;
}

.drp-dwn {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  width: 200px;
  padding: 12px;
  display: none;
  z-index: 99;
}

.activeShow {
  display: block !important;
}

.drp-dwn a {
  background: unset;
  background-color: #fff !important;
  font-weight: 400;
  text-align: left !important;
  font-size: 13px;
  width: 100%;
  display: block;
  padding: 0;
  height: auto;
  padding: 3px;
  margin: 5px 0;
}

.list_page_layout .header-mainMenu li a {
  color: #333;
  font-family: Poppins, sans-serif;
}

.menu {
  background: #fff;
  position: relative;
}

a.router-link-exact-active.router-link-active {
  color: #d72045 !important;
}

.header-mainMenu a:hover {
  color: unset;
}

.playstore-header {
  width: 20px;
  height: auto;
  cursor: pointer;
  object-fit: cover;
}
.drp-dwn {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    width: 200px;
    padding: 12px;
    display: none;
    z-index: 99;
}
.drp-dwn a, .drp-dwn a.router-link-exact-active.router-link-active {
    color: #000!important;
}
.drp-dwn a {
    background: unset;
    background-color: #fff!important;
    font-weight: 400;
    text-align: left!important;
    font-size: 13px;
    width: 100%;
    display: block;
    padding: 0;
    height: auto;
    padding: 3px;
    margin: 5px 0;
}
/* header-end */
.offcanvas-body {
  flex-grow: 1 !important;
  padding: 1rem 1rem !important;
  overflow-y: auto !important;
}
