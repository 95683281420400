@media only screen and (max-width: 1600px) {
  .footer-main svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .ad-sec>svg {
    position: absolute;
    left: 0;
    z-index: 0;
    top: 12%;
    max-width: 500px;
    max-height: 663px;
    width: 100%;
  }

  .testi_monial>img {
    width: 27%;
    margin-left: 2%;
  }


}

/* ***************************************************************** */
@media only screen and (max-width: 1400px) {
  .top-box * {
    font-size: 11px;
  }

  .ad-sec>svg {
    top: 6%;
    max-height: 590px;
    left: -17px;
  }

  .header-main1 a {
    font-size: 15px;
    line-height: 24px;
  }

  h2.tile-h,
  .booking_step h3 {
    font-size: 28px;
    line-height: 36px;
  }

  .title-sec-s a {
    font-size: 14px;
  }

  /* .single_box img {
    max-width: 110px;
  } */
  .single_box h3 {
    font-size: 18px;
    line-height: 22px;
    margin: 10px 0 10px;
  }

  .single_box a {
    font-weight: 400;
    font-size: 13px;
  }

  .top_tab {
    padding: 0 0 0;
  }

  .profile_det {
    max-width: 300px;
    padding-left: 75px;
    padding-right: 10px;
  }

  .tab_head ul li {
    font-size: 16px;
  }

  .authTitle h2 {
    font-weight: 700;
    font-size: 27px;
    line-height: 36px;
  }

  .form label {
    width: 100%;
    display: block;
    padding: 5px 0;
    font-size: 16px;
    line-height: 26px;
  }

  .booking_block .date {
    font-size: 25px;
    padding: 15px;
  }

  .header-main2 img {
    max-width: 200px;
  }

  .fav_list .profile_det {
    max-width: 280px;
    padding-left: 55px;
    padding-right: 10px;
  }

  .fav_list .profile_img {
    width: 110px;
    height: 110px;
  }

  .fav_list .profile_det {
    max-width: 325px;
    padding: 10px;
    padding-left: 80px;
    padding-right: 10px;
  }

  .patient_sum .info_line span,
  .patient_sum .info_line p {
    font-size: 15px;
    line-height: 23px;
  }

  .summary_doc_info img {
    width: 180px;
    height: auto;
    max-height: 230px;
    margin-bottom: 20px;
  }

  .name_sum h3 {
    font-size: 17px;
    line-height: 27px;
  }

  .name_sum span {
    font-weight: 300;
    font-size: 12px;
    line-height: 21px;
  }

  .tab-head_date div {
    font-size: 15px;
  }

  .tab-head_date div span {
    font-size: 12px !important;
  }

  .rate {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
  }

  .booking_date_det h2 {
    margin: 0;
    font-size: 22px;
  }

  .single-news h3 {
    font-size: 25px;
    -webkit-line-clamp: 50;
  }

  .bf span {
    font-size: 16px;
    line-height: 30px;
  }
}

/* ***************************************************************** */
@media only screen and (max-width: 1300px) {
  span.quote svg {
    height: 20px;
  }

  .comment-block {
    padding: 10px 20px;
  }

  .comment-block p {
    height: 100px;
    overflow: auto;
    font-size: 15px;
  }

  .comments {
    margin-top: 30px;
  }

  .testi_monial>img {
    margin-bottom: -2%;
  }

  .footer-main>img {
    height: 85%;
  }

  .footer-main {
    margin-top: -7%;
  }
}

/* ***************************************************************** */
@media only screen and (max-width: 1200px) {
  .header-main2 img {
    max-width: 190px;
  }

  .featured_speciality {
    padding: 40px 0 60px;
  }

  .profile_img {
    width: 110px;
    height: 110px;
  }

  .profile_det {
    max-width: 285px;
    padding: 15px;
    padding-right: 10px;
    padding-left: 68px;
  }

  .profile_det h3 {
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
  }

  .profile_det span {
    font-weight: 300;
    font-size: 14px;
  }

  .book_area>div {
    z-index: 2;
  }

  img.all_b {
    margin-top: -7%;
    max-height: 480px;
  }

  .box-news img {
    width: 100%;
    height: 290px;
  }

  .box-news:nth-child(2) img,
  .box-news:nth-child(4) img,
  .box-news:nth-child(5) img,
  .box-news:nth-child(7) img {
    height: 180px;
  }

  .testi_monial>img {
    margin-bottom: -3%;
  }

  .comment-block p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 10px;
  }

  span.quote svg {
    width: 30px;
    height: 22px;
  }

  .comment-block span {
    margin-bottom: 5px;
  }

  .comment-sec p {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    max-width: 90%;
    margin: 0;
  }

  .comments {
    margin-top: 20px;
  }

  .footer-menu {
    width: 90%;
  }

  .fav_list .profile_det {
    max-width: 285px;
  }

  .box-news {
    width: 23%;
  }

  .basic_info {
    width: 38%;
  }

  .booking_info_b {
    width: 37%;
    margin-left: auto;
    padding-top: 15px;
    padding-left: 0;
  }

  span.fees_b,
  .loc p {
    font-size: 14px;
  }

  .timeblock div {
    font-size: 10px;
  }
}

/* ***************************************************************** */
@media only screen and (max-width: 1190px) {
  .profile_det {
    max-width: 400px;
  }

  .tab-co {
    min-height: 350px;
    justify-content: space-between;
  }

  .book_area h3 {
    font-weight: 700;
    font-size: 28px;
  }

  .fav_list .profile_det {
    max-width: 560px;
  }

  .newsList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .showpopup .tab-c_date div.activeTab {
    max-height: 100% !important;
  }

  .tab-co .top-profile.single_profile {
    width: 47%;
  }
}

/* ***************************************************************** */
@media only screen and (max-width: 1024px) {
  .form-sec h1 {
    font-size: 26px;
  }

  .header-mainMenu .main_menu_list_ul {
    gap: 12px !important;
  }

  .header-mainMenu .main_menu_list_ul li a {
    font-size: 14px !important;
  }

  .SearchForm {
    width: 690px;
  }

  .search_tab ul li {
    font-size: 14px;
  }

  .banner-content img {
    max-width: 470px;
    position: absolute;
    right: -6%;
    bottom: 17%;
  }

  .round {
    position: absolute;
    right: -20%;
    width: 520px;
    height: 118px;
    background: #fff2f3;
    border-radius: 50%;
    bottom: 2%;
    z-index: 0;
  }

  .homeBanner {
    height: 560px;
    position: relative;
  }

  .homeBanner>svg {
    position: absolute;
    right: 0;
    top: -35px;
    width: 100%;
  }

  .banner {
    padding-bottom: 0;
  }

  h2.tile-h,
  .booking_step h3 {
    font-size: 25px;
    line-height: 28px;
  }

  .title-sec-s a {
    font-size: 13px;
  }

  /* .single_box img {
  max-width: 90px;
} */
  .single_box h3 {
    font-size: 17px;
    line-height: 22px;
    margin: 10px 0 5px;
  }

  .title-sec-s {
    margin-bottom: 45px;
  }

  .steps {
    gap: 30px;
    max-width: 80%;
    margin: 5% auto 0px;
  }

  .step div img:nth-child(1) {
    max-width: 110px;
  }

  .step p {
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #292929;
    max-width: 125px;
  }

  .profile_det {
    max-width: 380px;
  }

  .tab_head ul li {
    font-size: 14px;
  }

  .book_area button {
    font-size: 16px;
  }

  .book_area p {
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
  }

  .bookings_top {
    padding: 50px 0 140px;
  }

  img.all_b {
    margin-top: -7%;
    max-height: 420px;
  }

  .box-news img {
    width: 100%;
    height: 210px;
  }

  .news_d h3 {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
  }

  .news_d p {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    margin: 10px 0 0;
  }

  .box-news:nth-child(2) img,
  .box-news:nth-child(4) img,
  .box-news:nth-child(5) img,
  .box-news:nth-child(7) img {
    height: 150px;
  }

  .box-news:nth-child(6),
  .box-news:nth-child(8) {
    margin-top: -6%;
  }

  .box-news {
    border-radius: 10px;
  }

  .menu_button span {
    width: 100%;
    height: 3px;
    display: block;
    background: #c70419;
    transition: 0.4s;
  }

  .activebox .menu_button span:nth-child(1) {
    transform: translate(0, 12px) rotate(-50deg);
  }

  .activebox .menu_button span:nth-child(2) {
    opacity: 0;
  }

  .activebox .menu_button span:nth-child(3) {
    transform: translate(0, -3px) rotate(47deg);
  }

  .menu_button div {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 35px;
  }

  .header-main1 {
    display: none;
  }

  .menu_button {
    display: none;
    position: relative;
    z-index: 99;
  }

  .footer-menu {
    margin-top: 0 !important;
  }

  .edit-h4 {
    font-size: 10px !important;
  }

  .main-h4 {
    font-size: 18px !important;
  }


  /* ----------------------------------------------
 * Generated by Animista on 2023-3-21 8:16:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
  @-webkit-keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
      transform: translateX(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
      transform: translateX(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  .activebox .header-main1 {
    display: block;
    position: fixed;
    top: 110px;
    background: #fff;
    width: 40%;
    padding: 30px;
    float: right;
    right: 5%;
    height: 85vh;
    z-index: 99999;
    box-shadow: 1px 1px 4px 4px rgb(0 0 0 / 25%);
    -webkit-animation: fade-in-right .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-right .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  /* .activebox .header-main1 {
  display: block;
  position: fixed;
  top: 110px;
  background: #ffc6ccfa;
  width: 40%;
  padding: 30px;
  float: right;
  right: 5%;
  height: 100vh;
  z-index: 99999;
  box-shadow: 1px 1px 4px 4px rgb(0 0 0 / 25%);
} */
  .activebox .header-main1 ul {
    list-style: none;
    display: flex;
    gap: 16px;
    padding: 0;
  }

  .activebox ul {
    flex-wrap: wrap;
    padding: 0;
  }

  .activebox ul li,
  .activebox ul li a {
    width: 100%;
    color: black;
  }

  .left-sidebar {
    display: none;
  }

  .left-sidebar.activeSortBox {
    display: block;
    position: absolute;
    right: 0;
    top: 35px;
    width: 100%;
    background: white;
    z-index: 99;
  }

  .box_listing_main {
    position: relative;
    flex-wrap: wrap;
  }

  .list_view {
    width: 100%;
    margin-left: auto;
  }

  /* .filter_button {
  padding: 6px 17px;
  background: #ababab;
  margin-left: auto;
} */
  .filter_button {
    padding: 6px 10px;
    background: #ababab;
    margin-left: auto;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .filter_button {
    display: block;
  }

  .account-info {
    width: 100%;
  }

  .activeSortm .account-info {
    opacity: .3;
  }

  /* .bg-scroll .header-main + div {
  opacity: .5;
} */
  .mobile_srch_s {
    display: block !important;
  }

  .desk_top {
    display: none !important;
  }

  .modify_search.mobile_srch_s {
    background: white !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .modify_search button {
    width: 100%;
    margin: 15px 0 0;
  }

  .modify_search .form-c * {
    width: 100%;
  }

  .modify_search .form-c {
    width: 100%;
    margin: 0;
    flex-wrap: wrap;
  }

  .abt_flex {
    display: flex;
    margin: 30px 0;
    flex-wrap: wrap;
  }

  .co_abt {
    width: 100%;
    order: 2;
  }

  .co_abt h2 {
    font-size: 28px;
    margin: 0 0 15px;
  }

  .abt_flex img {
    max-width: 40%;
    margin-left: 20%;
    margin-bottom: -40px;
  }

  .bk_id {
    width: 35%;
  }

  .bk_id p {
    font-weight: 600;
    font-size: 25px;
    line-height: 36px;
  }

  p.psd {
    max-width: 100% !important;
    font-size: 17px;
    font-weight: 400;
    margin-top: 10px !important;
  }

  .left_info h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
  }

  .left_info>span {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }

  .info_d_c h3 {
    font-size: 20px;
    line-height: 31px;
  }

  .info_d_c>span {
    font-weight: 300;
    font-size: 15px;
    line-height: 27px;
  }

  .info_d_c p {
    font-weight: 500;
    font-size: 16px;
    line-height: 33px;
  }

  .bk_id {
    padding: 20px;
    width: 30%;
  }

  .account-info {
    padding: 0;
  }

  .change_password,
  .detaile_booking {
    padding: 25px;
    box-sizing: border-box;
  }

  .fav_list {
    padding: 25px;
  }

  .fav_list .profile_det {
    max-width: 350px;
  }

  .account-info .bookings,
  .account-info .History,
  .fv-cov,
  .change_password {
    padding: 25px;
  }

  .summary {
    flex-wrap: wrap;
  }

  .summary_doc_info {
    width: 35%;
  }

  .patient_sum {
    /* margin-right: auto; */
    width: 50%;
  }

  .payment_sum {
    width: 60%;
  }

  .wsum {
    margin-bottom: 40px;
  }

  .patient_sum {
    border-right: unset;
  }

  .booking_date_det h2 {
    margin: 0;
    font-size: 20px;
  }

  .box_flex {
    gap: 25px;
    flex-wrap: wrap;
  }

  .box_1_d {
    width: 100%;
  }

  .box_2_d {
    width: 100%;
  }

  .hideOnWeb {
    display: flex !important;
  }

  .hideOnmob {
    display: none !important;
  }

  .footer-menu {
    flex-wrap: wrap;
  }

  /* .menu-f {
  width: 100%;
} */
  .footer-main {
    margin-top: 0;
  }

  .footer-main>img {
    height: 185%;
  }

  .footer-menu {
    margin-top: 0;
  }

  .footer-menu {
    margin-top: 0;
  }

  .menu-f {
    width: 55%;
  }

  .testi_monial>img {
    margin-bottom: -7%;
  }

  .owl-dots {
    margin-top: 30px !important;
    margin-bottom: 20px;
  }

  .footer-main {
    height: auto;
    padding: 50px 0;
  }

  .testi_monial>img {
    width: 30%;
    margin-left: 2%;
  }

  .box-news {
    width: 31%;
    margin: 0 !important;
  }

  .box-news img {
    width: 100%;
    height: 230px !important;
  }

  .banner {
    overflow: hidden;
  }

  .homeBanner>svg {
    position: absolute;
    right: -14%;
    top: -73px;
    width: 100%;
  }

  .left-sidebar.activeSortBox {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 0;
    align-content: baseline;
    padding-right: 0 !important;
    padding-left: 0 !important;

  }

  .left-sidebar.activeSortBox>* {
    padding: 0 15px !important;
  }

  .left-sidebar h2 {
    order: 1;
  }

  .filter-box {
    width: 100%;
    order: 2;
    margin-top: 0;
  }

  .modify_search.mobile_srch_s {
    order: 3;
  }

  .filter-box {
    padding-bottom: 10px !important;
    /* border-bottom: 1px solid #dfdada; */
    margin-bottom: 20px !important;
  }

  .patient_sum {
    /* margin-right: auto; */
    width: 57%;
  }

  .summary {
    gap: 30px;
  }

  .mob-men {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  a.drp-clc svg {
    height: 17px !important;
  }

  a.drp-clc svg:last-child {
    height: 12px !important;
  }

  a.drp-clc {
    border: transparent;
    background: unset !important;
    color: #1a1a1a !important;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px !important;
  }

  .filter_button.rm {
    color: white;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 15px;
    cursor: pointer;
    background: #d2042d;
    padding: 6px 26px;
    border-radius: 5px;
    margin-right: 5px;
  }

  img.newsIm {
    object-position: center;
    margin: 0 auto;
    display: block;
  }

  .filter_button.icon-only {
    background: transparent;
    font-size: 25px;
    padding: 0;
    position: relative;
    z-index: 99999;
  }

  .left-sidebar.changesortBox {
    display: block;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    background: white;
    z-index: 99;
    left: 0;
    width: 100%;
    max-width: 100%;
    top: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000030;
    height: 100%;
  }

  .changesortBox .filter-box {
    display: none;
  }

  .changesortBox>h2 {
    display: none;
  }

  .changesortBox .modify_search.mobile_srch_s {
    position: absolute;
    bottom: 0;
    padding: 60px !important;
    border-radius: 25px 25px 0 0 !important;
    width: 100%;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    background: white;
    margin: 0 !important;
  }

  /* .changesortBox.sortonly .filter-box  {
  position: absolute;
  bottom: 0;
  padding: 30px 60px !important;
  border-radius: 25px 25px 0 0 !important;
  width: 100%;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  background: white;
  margin: 0 !important;
} */
  .filter_button.icon-only:nth-child(2) {
    margin-left: 10px;
  }

  .hideSrchFld.activeSortBox .modify_search.mobile_srch_s {
    display: none !important;
  }

  .changesortBox.sortonly .filter-box {
    display: block !important;
  }

  .hideSrchFld>h2 {
    display: none;
  }

  .left-sidebar.activeSortBox.hideSrchFld {
    height: auto;
    min-height: 0;
  }
}

/* ***************************************************************** */
@media only screen and (max-width: 900px) {
  .profile_det {
    max-width: 320px;
  }

  .book_area h3 {
    font-weight: 700;
    font-size: 24px;
  }

  .book_area p {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
  }

  .menu-f {
    width: 55%;
    gap: 70px;
  }

  .menu_footer_block h3 {
    font-weight: 600;
    font-size: 20px;
    ;
    margin: 0 0 10px;
  }

  .menu_footer_block li,
  .menu_footer_block li a {
    font-size: 14px;
    line-height: 22px;
  }

  .footer-main {
    position: relative;
    /* height: 470px; */
  }

  .header-main1 a {
    font-size: 13px;
    line-height: 24px;
  }

  .signin-bt a {
    color: white;
    height: 35px;
    /* width: 140px; */
  }

  .ads-main2 img {
    width: 45%;
    max-height: 100px;
  }

  h2.tile-h,
  .booking_step h3 {
    font-size: 23px;
    line-height: 28px;
  }

  .steps .step div .pos-img {
    max-width: 40px;
  }

  .step div img:nth-child(1) {
    max-width: 100px;
  }

  .ad-sec>svg {
    max-width: 45%;
  }

  .booking_step {
    margin: 65px 0 50px;
  }

  .bookings_top .title-sec-s {
    margin-bottom: 0px;
  }

  .tab_head {
    margin-bottom: 30px;
  }

  img.all_b {
    margin-top: -7%;
    max-height: 360px;
  }

  p.sub_p {
    margin: 0px 0 15px;
  }

  .box-news:nth-child(6),
  .box-news:nth-child(8) {
    margin-top: -7%;
  }

  .comment-sec h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
  }

  .comment-sec p {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
  }

  .comment-sec {
    width: 67%;
  }

  .comment-sec p {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  button.owl-dot span {
    width: 8px !important;
    height: 8px !important;
  }

  .owl-dots {
    margin-top: 15px !important;
  }

  .menu-f {
    width: 50%;
    gap: 70px;
  }

  .mail-s {
    width: 40%;
    text-align: center;
  }

  .sub_sc input {
    border-radius: 36px;
    padding: 10px 50px 10px 25px;
  }

  .sub_sc img {
    height: 40px;
  }

  /* .footer-main {
  position: relative;
  height: 425px;
} */
  img.gplay {
    max-width: 200px;
  }

  .flex_counter {
    display: flex;
    flex-wrap: wrap;
    margin: 50px 0;
  }

  .aim_box {
    width: 100%;

    margin: 0;

  }

  .countre_box {
    background: white;
    width: 30%;
  }

  .contact_form {
    flex-wrap: wrap;
  }

  .sontact_info {
    display: flex;
    width: 100%;
  }

  .sontact_info img {
    width: 23%;
    margin-left: -10px;
    height: auto;

    object-fit: contain;
  }

  .box_x {
    padding-top: 6%;
    margin-left: 2%;
  }

  .cfo {
    width: 100%;
  }

  .sontact_info {
    width: 100%;
    align-items: center;
  }

  .contact_form {
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .social_c {
    display: flex;
    gap: 12px;
    margin-top: 40px;
  }

  .social_c a {
    width: 40px;
    font-size: 20px;
    height: 40px;
  }

  .fav_list .profile_det {
    max-width: 300px;
  }

  .patient_sum {
    /* margin-right: auto; */
    width: 57%;
  }

  .summary {
    gap: 30px;
  }

  .box_listing_main {
    gap: 0;
  }

  .details_b p b,
  .details_b p span {
    font-size: 17px;
  }

  .booking_bar_info .date {
    font-size: 20px;
    max-width: 140px;

  }

  .booking_fe_f p {
    font-size: 16px;
  }

  .booking_fe_f span {
    font-size: 20px;
  }

  .banner-content {
    margin-top: 70px;
  }

}

/* ***************************************************************** */
@media only screen and (max-width: 768px) {
  .step div img:nth-child(1) {
    max-width: 90px;
  }

  .view_profile {
    float: right !important;
  }

  .my-account.padding-change {
    padding: 20px !important;
  }

  .document {
    padding-top: 10px;
  }

  .document input {
    padding-top: 10px;
  }

  .SearchForm {
    width: 690px;
    max-width: 95%;
  }

  .form-c select,
  .form-c input {
    padding: 7px 5px;
    font-size: 12px;
  }

  .search_tab ul li {
    font-size: 12px;
  }

  .banner-content img {
    max-width: 335px;
    position: absolute;
    right: -8%;
    bottom: 31%;
  }

  .form-sec h1 {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .homeBanner {
    height: 500px;
    position: relative;
  }

  .form-sec {
    margin-left: 0;
    margin-bottom: 6%;
  }

  h2.tile-h,
  .booking_step h3 {
    font-size: 21px;
    line-height: 26px;
  }

  .title-sec-s a {
    font-size: 10px;
    line-height: 16px;
  }

  .booking_step h3 {
    font-size: 20px;
  }

  .steps {
    gap: 30px;
    max-width: 100%;
    margin: 5% auto 0px;
  }

  .bookings_top {
    padding: 30px 0 140px;
  }

  .authform {
    width: 100%;
  }

  .round_msg {
    width: 290px;
    height: 290px;
    right: 0;
    top: 0;
    z-index: 1;
  }

  img.pos-image-auth {
    width: 55%;
  }

  .forgot .authform {
    width: 100%;
    order: 2;
  }

  .layout-sec {
    padding-top: 40px;
    margin-bottom: 45px;
  }

  .forgot img.pos-image-auth {
    width: 77%;
    height: 250px;

    margin: 0 auto;

  }

  .top-box {
    /* display: flex; */
    align-items: center;
    gap: 10px;
    border-right: 1px solid #B2B2B2;
    padding-right: 10px;
    height: 30px;
  }

  .top-box * {
    font-size: 9px;
  }

  .header-top .wrapper {
    gap: 10px;
  }

  img.pos-image-auth {
    width: 55%;
    height: 270px;
  }

  .auth-right {
    position: relative;
  }

  .authform {
    width: 100%;
    order: 2;
  }

  .round_msg h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    max-width: 75%;
  }

  .round_msg p {
    margin: 0px 0;
    font-size: 16px;
  }

  .round_msg a {
    margin-top: 5px;
    font-size: 16px;
    font-weight: bold;
  }

  .round_msg {
    width: 235px;
    height: 235px;
    top: 0px;
  }

  .basic_info h2 {
    font-size: 18px;
    line-height: 25px;
  }

  .basic_info span {
    font-size: 13px;
    line-height: 21px;
  }

  .star-rate {
    display: flex;
    gap: 4px;
    padding: 5px 0;
  }

  p.exp {
    font-weight: 500;
    font-size: 13px;
  }

  .loc p {
    font-size: 13px;
    line-height: 20px;
  }

  span.fees_b {
    margin: 0 0 25px;
    font-weight: 500 !important;
    font-size: 15px;
    line-height: 19px;
  }

  button.bookNow_b,
  button.view_profile {
    font-size: 13px;
    line-height: 22px;
    border: unset;
    width: 135px;
    padding: 8px;
    margin-top: auto;
    margin-bottom: 0px;
  }

  .s_img img {
    width: 100%;
    max-height: 215px;
    min-height: 200px;
  }

  .form-fl {
    width: 47%;
  }

  /* .profile-img img {
  max-height: 180px;
} */
  .detrail-form label {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .detrail-form input,
  .detrail-form textarea {
    width: 100%;
    padding: 5px;
  }

  .detrail-form {
    gap: 20px;
    margin-top: 20px;
  }

  .bt_bk {
    margin-left: auto;
    width: 23%;
    padding-right: 10px;
    box-sizing: border-box;
  }

  .ad-sec>svg {
    top: -30%;
  }

  .signin.forgot.OTPs img.pos-image-auth {
    margin-right: auto;
    margin-left: auto;
    width: 58%;
    height: 198px;
  }

  .b_det {
    max-width: 50%;
  }

  .b_det button.Confm {
    width: 100%;
    padding: 10px;
    border: unset;
    font-size: 14px;
  }

  .details_b p b,
  .details_b p span {
    font-size: 16px;
    line-height: 27px;
  }

  .banner_det b {
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
  }

  .banner_det p {
    font-size: 18px;
    line-height: 33px;
    margin: 5px 0;
  }

  .banner_det span {
    font-size: 17px;
    line-height: 27px;
  }

  .banner_det {
    margin-top: 30px;
    padding: 10px 0;
  }

  span.spnsrd {
    font-size: 8px !important;
    padding: 5px 7px;
    border-radius: 10px;
    margin-left: 3px;
  }

  .booking_confirm_info {
    flex-wrap: wrap;
    background: white;
    padding: 15px 25px;
  }

  .left_info {
    width: 100%;
  }

  .bk_id {
    background: #C70419;
    padding: 20px;
    width: 100%;
    margin-top: 20px;
  }

  .booking_block .date {
    font-size: 20px;
    padding: 15px;
  }

  .fav_list .profile_det {
    max-width: 550px;
    width: 90%;
  }

  .fav_list .single_profile {
    width: 100%;
  }

  .history_info_b h2 {
    font-size: 14px;
    margin: 0;
  }

  .history_info_b span {
    font-size: 11px;
  }

  .history_info_b button.view_profile {
    max-width: 90px;
    font-size: 11px;
    padding: 3px 0;
  }

  .checkb {
    width: 100%;
  }

  .updateSuccess {
    margin-bottom: 25px;
    top: 55%;
    left: 5%;
    margin-right: 0;
    transform: unset;
    border-radius: 5px;
    z-index: 999;
    width: 90%;
    height: 20%;
  }

  .updateSuccess.popup-login {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .popup-login>div {
    padding: 15px;
    box-sizing: border-box;
  }

  .mine {
    position: relative;
  }

  .edit_profile {
    position: absolute;
    right: 6px;
    top: 5px;
  }

  .popup-login>div {
    width: 90%;
  }

  .fields-fam.adrs-fm.change_password_fm {
    margin-top: 0;
  }

  .box-news {
    width: 47%;
    margin: 0 !important;
  }

  .box-news img {
    width: 100%;
    height: 210px !important;
  }

  .newsList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .menu-f {
    width: 100%;
    gap: 70px;
    margin-bottom: 25px;
  }

  .mail-s {
    width: 50%;
    text-align: center;
    margin: 0 auto;
  }

  .booking_confirm_info .left_info {
    width: 100%;
  }

  .single-news h3 {
    font-size: 21px;
    -webkit-line-clamp: 50;
  }

  .co-news.single-det-news {
    margin-top: 15px;
  }

  .popup_date.showpopup {
    right: 0;
    z-index: 9;
    width: 145%;
    left: unset;
  }

  .summary_doc_info {
    width: 100%;
  }

  .patient_sum {
    /* margin-right: auto; */
    width: 100%;
  }

  .family_Block {
    width: 100%;
  }

  .account-info .bookings,
  .account-info .History,
  .fv-cov,
  .change_password {
    padding: 0;
  }

  .booking_block .date {
    font-size: 16px;
    padding: 15px;
  }

  .d_details {
    width: 35%;
  }

  .b_det {
    min-width: 60%;
    max-width: 60%;
    ;
  }

  .tab_head li.active::after,
  .tab_head li.active:hover:after {
    display: none;
  }

  a.bcktoHome.router-link-active {
    /* padding: 0 0 10px; */
    display: block;
    width: auto;

    font-size: 13px;
  }

  .booking_bar_info {
    flex-wrap: wrap;
  }

  .booking_bar_info .date {
    font-size: 20px;
    max-width: 100%;
    width: 100%;
    word-spacing: 0;
  }

  .img_bk {
    width: 27%;
    padding-left: 20px;
  }
  .profile-individual h2 {
    font-size: 18px;
}
.user-wishlist {
  font-size: 10px!important;
}
.doctor-details {
  height: auto!important;
}
}

/* ***************************************************************** */
@media only screen and (max-width: 660px) {
  .d_details {
    width: 100%;
  }

  .img_bk {
    padding-left: 10px;
  }

  .b_det {
    min-width: 100%;
    max-width: 100%;
    ;
  }

  .div_flex_bk {
    flex-wrap: wrap;
  }

  .togglepay {
    display: none;
  }

  .summaryH {
    margin-top: 0;
  }

  .booking_confirm_info h2 {
    text-align: center;
    justify-content: center;
  }

  .top-box-header * {
    font-size: 11px !important;
  }

  .banner_head {
    font-size: 20px !important;
  }

  .rec-bookrd {
    padding: 0;
  }

  h2.tile-h {
    font-size: 18px !important;
  }

  .mainP.HomePage .title-sec-s a {
    background: #ff283e !important;
    color: #fff;
  }
}

/* ***************************************************************** */
@media only screen and (max-width: 600px) {
  .SearchForm {
    width: 690px;
    max-width: 72%;
  }

  .banner-content img {
    max-width: 240px;
  }

  .form-sec h1 {
    font-size: 21px;
    margin-bottom: 30px;
  }

  .homeBanner {
    height: 550px;
    position: relative;
  }

  .book_area h3 {
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
  }

  .book_area {
    flex-wrap: wrap;
  }

  .book_area>div {
    z-index: 2;
    padding: 30px 0 30px;
  }

  img.all_b {
    margin-top: -7%;
    max-height: 253px;
    position: relative;
    z-index: 3;
    margin: -10% auto 0;
  }

  .profile_det {
    max-width: 425px;
  }

  .steps {
    flex-wrap: wrap;
    gap: 20px;
  }

  .step {
    width: 40%;
  }

  .step p {
    max-width: 100%;
  }

  .ads-main2 {
    gap: 10px;
    margin-bottom: 30px;
  }

  .ads-main2 img {
    width: 49%;
    max-height: 100px;
  }

  button.owl-dot.active span {
    background: #C70419 !important;
    width: 8px !important;
    height: 8px !important;
  }

  /* .single_box img {
  max-width: 80px;
} */
  .single_box h3 {
    font-size: 15px;
    line-height: 22px;
    margin: 10px 0 3px;
  }

  .single_box a {
    font-weight: 400;
    font-size: 11px;
  }

  .featured_speciality {
    padding: 25px 0 25px;
  }

  .title-sec-s {
    margin-bottom: 30px;
  }

  .form-c {
    flex-wrap: wrap;
  }

  /* .form-c select, .form-c input {
  width: 48%;
  box-sizing: border-box;
} */
  .form-c select,
  .form-c input,
  .form-c button {
    width: 100%;
    box-sizing: border-box;
  }

  .form-c {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .banner-content img {
    max-width: 335px;
    position: absolute;
    right: -5%;
    bottom: 41%;
  }

  .form-sec h1 {
    font-size: 21px;
    margin-bottom: 30px;
    max-width: 215px;
  }

  p.sub_p {
    margin: 6px 0 20px;
    font-size: 13px;
    line-height: 20px;
  }

  .box-news {
    width: 47%;
    margin: 0 !important;
  }

  .box-news img {
    width: 100%;
    height: 170px !important;

  }

  .news_d {
    padding: 10px;
  }

  .news_d h3 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }

  .news_d p {
    font-weight: 400;
    font-size: 10px;
    line-height: 19px;
    margin: 6px 0 0;
  }

  .testi_monial>img {
    margin-bottom: -3%;
    display: none;
  }

  .comment-sec {
    width: 85%;
    margin: 0 auto;
  }

  .footer-main {
    position: relative;
    /* height: 425px; */
    margin: 0;
  }

  .footer-menu {
    width: 90%;
    flex-wrap: wrap;
  }

  .menu-f {
    width: 100%;
    gap: 20px;
  }

  .menu_footer_block h3 {
    font-weight: 600;
    font-size: 17px;
    margin: 0 0 5px;
  }

  .mail-s {
    width: 100%;
    text-align: center;
  }

  .footer-main>img {
    height: 175%;
  }

  .footer-menu {
    width: 90%;
    flex-wrap: wrap;
    margin-top: 0%;
  }

  div#Footer {
    margin-top: 50px;
  }

  .mail-s {
    margin-top: 20px;
  }

  .book_area button {
    font-size: 14px;
  }

  .booking_step h3 {
    font-size: 18px;
  }

  .booking_step {
    margin: 40px 0 20px;
  }

  .authform>div {
    padding: 20px;
    width: 100%;
    padding-bottom: 15px;
  }

  .form input {
    padding: 6px;
  }

  .authTitle h2 {
    font-weight: 700;
    font-size: 22px;
    line-height: 36px;
  }

  .form label {
    padding: 5px 0;
    line-height: 26px;
  }

  .form button {
    padding: 8px;
    margin-top: 16px;
    font-size: 17px;
  }

  img.pos-image-auth {
    width: 46%;
    height: 308px;
  }

  .round_msg {
    z-index: 17;
  }

  .round_msg h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

  }

  .round_msg p {
    margin: 0 0;
    font-size: 14px;
    line-height: 29px;
  }

  .round_msg a {
    margin-top: 0;
    font-size: 16px;
    font-weight: bold;
  }

  /* .round_msg {
  width: 215px;
  height: 215px;
} */

  .footer-menu {
    margin-top: 10%;
  }

  .round_msg {
    width: 217px;
    height: 217px;
    bottom: 30px;
  }

  .provider {
    flex-wrap: wrap;
  }

  .s_img {
    width: 40%;
  }

  .basic_info {
    width: 55%;
    border-right: unset;
  }

  .booking_info_b {
    width: 100%;
  }

  .loc_info {
    width: 100%;
  }

  .loc svg {
    margin-top: 2px;
  }

  .box_listing_main {
    gap: 10px;
  }

  .filter_button {
    padding: 5px 17px;
    background: #ababab;
    margin-left: auto;
    font-size: 12px;
  }

  .breadcrumbs h2 {
    font-size: 22px;
    margin: 0 0 15px;
  }

  .left-sidebar h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    margin-bottom: 10px;
  }

  .filter-box {
    margin: 20px 0 0;
  }

  .filter-box h3 {
    font-weight: 500;
    font-size: 17px;
    margin: 0;
    margin-bottom: 12px;
  }

  .filter-box li {
    font-size: 14px;
  }

  .form-fl {
    width: 100%;
  }

  .booking_d h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .booking_d span {
    font-size: 11px;
    line-height: 16px;
  }

  .accout_sidebar.left-sidebar li a {
    font-size: 14px;
    line-height: 20px;
    color: #333333;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .tab_head ul {
    padding: 0px 0 0 !important;
    margin: 14px 0 0;
  }

  .tab_head {
    margin-bottom: 10px;
  }

  .bookings_top {
    padding: 25px 0 85px;
  }

  .div_flex_bk {
    flex-wrap: wrap;
  }

  .b_det {
    max-width: 100%;
  }

  .box_x h3 {
    font-size: 25px;
    margin: 0 0 13px;
  }

  .cbox_s a {
    font-size: 15px;
  }

  .sontact_info img {
    width: 26%
  }

  .social_c a {
    width: 35px;
    font-size: 16px;
    height: 35px;
  }

  .contact_form {
    margin: 40px 0;
  }

  .aim_box {
    margin: 0px auto 0px;
    /* max-width: 90%; */
  }

  .aim_box h3 {
    font-size: 20px;
  }

  .co_abt p,
  .aim_box p {
    text-align: justify;
  }

  span.fees_b.margin_t_f b {
    display: block;
    line-height: 23px;
  }

  .booking_bar_info {
    flex-wrap: wrap;
  }

  .booking_bar_info .date {
    width: 100%;
    max-width: 100% !important;
    font-size: 20px;
    padding: 5px;
  }

  .booking_bar_info .img_bk {
    max-width: 190px;
    width: 30%;
  }

  .booking_fe_f p {
    margin: 0;
  }

  .booking_fe_f {
    margin-right: auto;
    margin-left: 0;
    padding-right: 30px;
    width: 65%;
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 25px;
    padding-bottom: 25px;
    /* justify-content: end; */
  }

  .show_date_i {
    width: 100%;
  }

  .ap_field {
    width: 100%;
  }

  .full_button button {
    width: 100%;
    padding: 12px 10px;
    font-size: 15px;
  }

  .full_button {
    padding: 20px 0 10px;
  }

  .ap_field input {
    width: 100%;
    padding: 7px;
    font-size: 12px;

  }

  .ap_field label {
    font-size: 14px;
    padding-bottom: 6px;
  }

  .show_date_i span {
    display: block;
    font-size: 14px;
    line-height: 29px;
  }

  .Appointment_Form h2 {
    font-size: 20px;
    margin: 0;
  }

  .History_block h2 {
    margin: 0 0 15px;
    font-size: 18px;
  }

  .History_block h3 {
    font-size: 16px;
  }

  .History_block p {
    font-size: 16px;
    color: #555555;
    margin: 10px 0;
  }

  .History_block button {
    width: 100%;
    font-size: 16px;
    border-radius: 5px;
  }

  .History_list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .patient_sum {
    /* margin-right: auto; */
    width: 100%;
  }

  .summary_doc_info {
    width: 100%;
  }

  .summary_doc_info {
    width: 100%;
    display: flex;
    gap: 15px;
    align-items: flex-start;
  }

  .summary_doc_info img {
    width: 100px;
    height: auto;
    max-height: initial;
    margin-bottom: 0;
  }

  .name_sum h3 {
    font-size: 15px;
    line-height: 24px;
  }

  .name_sum .loc p {
    font-size: 12px;
    line-height: 21px;
  }

  .patient_sum .info_line span,
  .patient_sum .info_line p {
    font-size: 13px;
    line-height: 21px;
  }

  .patient_sum .info_line span {
    width: 40%;
  }

  .payment_sum {
    width: 100%;
  }

  .info_line.amount_line {
    font-size: 17px;
    line-height: 24px;
  }

  .summary {
    flex-wrap: wrap;
    gap: 25px;
    padding: 15px;
  }

  .summary_doc_info,
  .patient_sum {
    border-right: unset;
  }

  .bgw {
    padding: 0 20px;
  }

  .fields-fam {
    width: 100%;
  }

  .Appointment_Form {
    background: white;
    padding: 15px;
  }

  .checkb {
    width: 100%;
  }

  .account-info .bookings,
  .account-info .History,
  .fv-cov,
  .change_password {
    padding: 0;
  }

  .my_title_d {
    padding-left: 0px;
    padding-bottom: 20px;
    font-size: 20px;
  }

  .family_Block {
    width: 100%;
  }

  .full_fm.fam_list .family_Block {
    position: relative;
  }

  .full_fm.fam_list .family_Block .fm_actions {
    top: 30px;
  }

  .ExperienceEducation {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .tab-c {
    width: 100%;
  }

  .ExperienceEducation .tab-c * {
    color: #444444 !important;
    font-size: 14px;
  }

  .rate {
    font-weight: 600;
    font-size: 17px;
  }

  .tab-head_date div {
    font-size: 12px;
  }

  .tab-head_date div span {
    font-size: 10px !important;
    line-height: 22px;
  }

  .buttons_boo button {
    padding: 7px 0;
    width: 35%;
    font-size: 12px;
  }

  .buttons_det button {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    padding: 3px 10px;
  }

  .comment-block p {
    height: auto;
    overflow: auto;
  }

  .responsive-booking-blck.booking_block .date {
    font-size: 16px;
    padding: 10px;
    width: 100%;
    display: block !important;
    word-spacing: 0;
    max-width: 100%;

  }

  .booking_block.responsive-booking-blck {
    flex-wrap: wrap;
  }

  .booking_block.responsive-booking-blck .img_bk {
    width: 22%;
    padding: 10px 0 0 10px;
    box-sizing: border-box;
  }

  .booking_block.responsive-booking-blck .bt_bk button {
    display: block;
    width: 100%;
    padding: 8px;
    font-size: 12px;
  }

  .detaile_booking {
    background: white;
    padding: 20px !important;
  }

  .footer-main {
    height: auto;
    padding: 0;
  }

  .mail-s .menu_footer_block {
    display: flex;
    flex-wrap: wrap;
  }

  .sub_sc {
    width: 100%;
    position: relative;
    margin-top: 10px;
  }

  img.gplay {
    max-width: 100%;
  }

  .social {
    display: flex;
    gap: 10px;
    padding: 0;
    justify-content: center;
  }

  .sub_sc {
    margin-bottom: 20px;
  }

  .mail-s .menu_footer_block {
    justify-content: space-between;
  }

  .footer-main {
    padding-bottom: 25px;
  }

  img.gplay {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .store-ply {
    width: 50%;
  }

  .space-h2 {
    margin-top: 40px;
    font-size: 26px;
  }

  .popup_date.showpopup {
    right: unset;
    z-index: 9;
    width: 100%;
    left: 0;
  }

  .layout-sec {
    padding-top: 20px;
    margin-bottom: 35px;
  }

  .changesortBox .modify_search.mobile_srch_s {
    padding: 30px !important;

  }

  .filter_button.icon-only {
    background: transparent;
    font-size: 20px;
  }

  .tab-co .top-profile.single_profile {
    width: 100%;
  }

  .nomob {
    display: none !important;
  }

  .noweb {
    display: block !important;
  }

  a.show-hide-img.image img {
    max-height: 150px;
  }

  .tab-co .top-profile.single_profile {
    width: 100%;
    justify-content: center;
  }

  .ExperienceEducation .tab-c {
    width: 100%;
  }

  .ExperienceEducation {
    display: flex;
    gap: 0;
    flex-wrap: wrap;
  }

  .detailpage_contents {
    margin-bottom: 35px;
  }

  .white_d {
    background: white;
    padding: 20px 0 0;
  }

  .tab-c_date div.activeTab {
    gap: 18px;
  }

  .pagination {
    margin-top: 40px;
  }

  .pagination ul li:last-child,
  .pagination ul li:first-child {
    min-width: 50px;
    text-align: left;
    justify-content: flex-start;
  }

  .booking_bar_info .img_bk {
    width: 37%;
  }

  .footer-main {
    padding-top: 15px;
  }
}

/* ***************************************************************** */
@media only screen and (max-width: 580px) {
  .banner-content {
    position: relative;
    flex-wrap: wrap;
  }

  .form-sec {
    margin-left: 0;
    margin-bottom: 6%;
    order: 2;
  }

  .banner-content img {
    max-width: 335px;
    position: relative;
    right: 0;
    bottom: 0;
  }

  .banner-content img {
    max-width: 211px;
    position: relative;
    right: 0;
    bottom: 0;
    margin: 27% 0 -73px;
    margin-left: auto;
  }

  .homeBanner {
    height: auto;
    position: relative;
  }

  .form-sec h1 {
    font-size: 20px;
    margin-bottom: 25px;
    max-width: 100%;
  }

  .SearchForm {
    width: 100%;
    max-width: 100%;
    padding: 15px;
  }

  .search_tab ul {
    display: flex;
    list-style: none;
    justify-content: flex-start;
    color: #333333;
    gap: 15px;
    align-items: center;
    margin: 0;
    overflow: auto;
    width: 100%;
    padding: 0;
  }

  .title-sec-s a {
    font-size: 10px;
    line-height: 16px;
    color: #c70419;
    background: transparent;
  }

  .booking_step h3 {
    font-size: 16px;
  }

  .step div img:nth-child(1) {
    max-width: 83px;
  }

  .step p {
    max-width: 100%;
    margin: 7px 0 0px;
    line-height: 22px;
    font-size: 12px;
  }

  .profile_det {
    max-width: 300px;
  }

  .footer-main {
    position: relative;
    /* height: 450px; */
  }

  .form-sec h1 {
    font-size: 18px;
    margin-bottom: 12px;
    max-width: 58%;
    word-break: break-word;
  }

  .top-box.search-bar input::placeholder {
    font-size: 8px;
  }

  /* .top-box.search-bar {
  display: none;
} */
  .top-box.search-bar {
    margin-left: auto;
  }

  .top-box.search-bar input {
    display: none;
  }

  .top-box:nth-child(2) {
    border: unset;
    padding-right: 0;
  }

  .header-main2 img {
    max-width: 160px;
  }

  .menu_button div {
    gap: 2px;
    width: 26px;
  }

  .activebox .menu_button span:nth-child(1) {
    transform: translate(0, 7px) rotate(-50deg);
  }

  .activebox .menu_button span:nth-child(3) {
    transform: translate(0, -3px) rotate(47deg);
  }

  .activebox .header-main1 {
    display: block;
    position: fixed;
    z-index: 99999;
    top: 90px;
    width: 90%;
    padding: 30px;
    right: 5%;
    height: 85vh;
    box-sizing: border-box;
  }

  .single_box h3 {
    font-size: 14px;
    line-height: 22px;
    margin: 10px 0 0px;
  }

  .round_msg {
    width: 160px;
    height: 160px;
    top: 0px;
  }

  .round_msg h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .round_msg p {
    margin: 0 0;
    font-size: 10px;
    line-height: 21px;
  }

  .round_msg a {
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    padding: 4px;
    max-width: 90px;
  }

  img.pos-image-auth {
    width: 46%;
    height: 219px;
  }

  .authTitle h2 {
    font-weight: 700;
    font-size: 21px;
    line-height: 30px;
  }

  .form label {
    padding: 0px 0 5px;
    line-height: 26px;
    font-size: 14px;
  }

  .form input {
    padding: 4px;
  }

  .form button {
    padding: 8px;
    margin-top: 16px;
    font-size: 15px;
  }

  .footer-bt {
    font-size: 10px;
  }

  button.bookNow_b {
    font-size: 13px;
    line-height: 22px;
    border: unset;
    width: 135px;
    padding: 8px;
    margin-top: 9px;
    margin-bottom: 25px;
    min-width: 100%;
    box-sizing: border-box;
  }

  .basic_info h2 {
    font-size: 16px;
    line-height: 20px;
  }

  .qualifictn {
    font-weight: 400;
    font-size: 11px;
  }

  p.exp {
    font-weight: 500;
    font-size: 12px;
  }

  button.view_profile {
    line-height: 18px;
    color: #FFFFFF;
    border: unset;
    padding: 6px 15px;
    margin-top: 12px;
  }

  .s_img img {
    width: 100%;
    max-height: 180px;
    min-height: 180px;
    object-fit: contain;
  }

  .booking_info_b {
    margin-left: auto;
    padding-top: 0px;
    padding-left: 0;
  }

  .listt-sec {
    padding: 20px 0 35px;
  }

  .booking_block {
    flex-wrap: wrap;
  }

  .booking_block .date {
    font-size: 19px;
    padding: 6px;
    width: 100%;
    justify-content: center;
  }

  .img_bk {
    width: 25%;
    padding: 0px 0 0 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  .booking_d {
    width: 40%;
  }

  .bt_bk button {
    font-size: 12px;
    padding: 9px;
    margin: 10px 0;
  }


  .booking_block .date {
    font-size: 16px;
    padding: 3px;
  }

  .img_bk img {
    max-height: 125px;
    max-width: 124px;
  }

  .booking_d h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
  }

  p.time_bk {
    font-size: 12px;
    line-height: 22px;
    padding: 0;
    margin: 5px 0;
  }

  .bt_bk button {
    font-size: 10px;
    padding: 6px;
    margin: 7px 0;
  }

  .booking_d button {
    font-weight: 400;
    font-size: 11px;
    line-height: 22px;
    color: black;
    width: 95px;
    height: 25px;
    border: unset;
  }

  .homeBanner>svg {
    top: -145px;
    width: 100%;
  }

  .ad-sec>svg {
    top: -5%;
    max-height: 100%;
  }

  .lan select {
    font-size: 9px !important;
  }

  .mobile_top_form {
    display: block;
  }

  .mobile_top_form input {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto !important;
    display: block;
    padding: 5px;
  }

  .mobile_top_form {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000c0;
    z-index: 99 !important;
    left: 0;
    top: 0px;
    padding-top: 20%;
    display: none;
  }

  .close_b {
    position: absolute;
    right: 5%;
    top: 27px;
    background: #b70000;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
    cursor: pointer;
  }

  span.fees_b {
    margin: 0 0 0px;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 19px;
  }

  .mobile_top_form.showForm_b {
    display: block;
  }

  .fm_hd svg {
    position: absolute;
    right: 13px;
    top: 7px;
  }

  .fm_hd {
    position: relative;
    width: 90%;
    box-sizing: border-box;
    margin: 0 auto !important;
    display: block;
  }

  .sug,
  .hideOnlyMob {
    display: none !important;
  }

  .sugMob {
    display: none;
  }

  .showoNLYmOB {
    display: block !important;
  }

  .sugMob.showSug {
    display: block;
    width: 90%;
    z-index: 9999;
    position: relative;
    background: white;
    text-align: left;
    margin-bottom: auto;
    margin: 0 auto;
    max-height: 75vh;
    overflow: auto;
  }

  .sugMob.showSug li {
    text-transform: capitalize;
    padding: 6px 0;
  }

  .sugMob.showSug ul {
    list-style: none;
    padding: 7px 6px;
  }

  /* .basic_info h2 {
  position: relative;
} */
  span.spnsrd {
    font-size: 7px !important;
    padding: 2px 7px;
    border-radius: 5px;
    margin-left: 3px;
    display: block !important;
    max-width: 70px;
    text-align: center;
    line-height: 14px;
    margin: 6px 0;
  }

  .booking_fe_f {
    width: 100%;
  }

  .left_info h2 {
    font-size: 20px;
  }

  .left_info>span {
    font-size: 14px;
    line-height: 26px;
    margin-top: 0;
  }

  .info_d_c {
    margin-top: 13px;
  }

  .info_d_c h3 {
    font-size: 18px;
    line-height: 27px;
  }

  .info_d_c>span {
    font-weight: 300;
    font-size: 13px;
    line-height: 27px;
    margin-bottom: 10px;
  }

  .bk_id p {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }

  p.psd {
    max-width: 100% !important;
    font-size: 15px;
    margin-top: 6px !important;
  }

  .bk_id div span {
    font-size: 15px;
  }

  .bk_id div b {
    font-size: 25px;
    line-height: 43px;
  }

  .booking_info_1>.wrapper {
    padding: 30px 0 0;
  }

  .info_d_c p {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
  }

  .common_space {
    margin: 30px 0;
  }

  /* .my_title_d {
  padding-left: 25px;
  font-size: 20px;
  padding-bottom: 15px;
}
.History > div {
  margin-left: 25px;
  margin-right: 25px;
} */
  .fm_t {
    flex-wrap: wrap;
  }

  .name_fm {
    width: 100%;
    order: 2;
  }

  .fm_actions {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  .fam_book .family_Block {
    padding-top: 50px;
  }

  .checkb input {
    position: absolute;
    left: 12px;
    top: 55px;
  }

  .fm_det_c {
    font-size: 14px;
  }

  .newsList .box-news img {
    width: 100%;
    height: 180px !important;
  }

  .newsList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .newsList {
    margin: 35px 0;
  }

  .popup_date.insideAD.showpopup>div {
    width: 90%;
  }

  .notification-block>div:first-child {
    font-size: 25px;
  }

  .notification-block {
    background: white;
    padding: 10px;
  }

  .top-box {
    display: flex;
  }

  .profile_det {
    max-width: 370px;
  }

  .detail_page_personal {
    padding: 20px;
    font-size: 13px;
  }

  .tab-c_date .activeTab .timeblock {
    height: 79px;
  }
}

/* ***************************************************************** */
@media only screen and (max-width: 480px) {
  .ad-sec>svg {
    top: -15%;
    max-height: 100%;
  }

  .countre_box {
    background: white;
    width: 100%;
    margin: 0;
  }

  .counter {
    padding: 1px 0;
  }

  .abt_flex img {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }

  .co_abt h2 {
    font-size: 24px;
    margin: 0 0 15px;
  }

  .co_abt p {
    font-size: 14px;
    line-height: 24px;
  }

  .aim_box p {
    font-size: 14px;
    margin: 10px 0 0;
    line-height: 1.8;
  }

  .countre_box span {
    font-size: 30px;
    font-weight: bold;
  }

  .countre_box {
    padding: 35px 20px;
  }

  .aims {
    margin-bottom: 0;
  }

  .sontact_info img {
    width: 32%;
  }

  .cbox_s {
    font-size: 15px;
    padding: 14px 0 0 0px;
  }

  .social_c {
    margin-top: 30px;
  }

  .cfo label {
    font-size: 14px;
  }

  .cfo input,
  .cfo textarea {
    padding: 8px;
  }

  .basic_info {
    padding-top: 4px;
  }

  img.careerimg {
    max-width: 50% !important;
    margin-left: auto !important;
  }

  .openings>h2 {
    font-size: 22px;
    color: #ff5a0c;
    margin-bottom: 25px;
  }

  .cTitle {
    width: 100%;
    padding: 8px 10px;
    font-size: 15px;
  }

  .cTitle.activeAc+.cInfo {
    display: block;
    padding: 12px;
    font-size: 14px;
    line-height: 24px;
  }

  .top_auto {
    width: 100%;
    margin-top: 0;
  }

  button.view_profile {
    line-height: 18px;
    color: #FFFFFF;
    border: unset;
    padding: 5px 10px;
    margin-top: 10px;
    font-size: 13px;
    width: 100%;
  }

  .booking_d {
    display: flex;
    align-items: flex-start;
  }

  .fav_list .profile_img {
    width: 90px;
    height: 90px;
    margin-right: 0px;
    display: block;
    margin: 0 0 -40px;
  }

  .fav_list .profile_det {
    max-width: 550px;
    width: 100%;
    padding-left: 20px;
    padding-top: 50px;
  }

  .fav_list .single_profile {
    width: 100%;
    flex-wrap: wrap;
  }

  .basic_info {
    width: 53%;
  }

  span.spnsrd {
    position: absolute;
    left: -95%;
    bottom: 8px;
  }

  .service_block .s_img {
    max-height: 100%;
  }

  .basic_info {
    position: relative;
  }

  .s_img {
    width: 45%;
  }

  .basic_info {
    width: 50%;
  }

  .provider {
    gap: 10px;
  }

  .top_auto {
    margin-top: auto;
  }

  .basic_info .bs-in {
    align-items: flex-start;
  }

  .basic_info .bs-in svg.svg-inline--fa.fa-user {
    margin-top: 3px;
  }

  .ExperienceEducation .tab-c * {
    font-size: 12px;
  }

  .rate {
    font-weight: 600;
    font-size: 15px;
    margin: 10px 0 15px;
  }

  .image_round img {
    width: 75%;
    height: 75%;
  }

  .image_round svg {
    left: 48%;
  }

  .round-info p {
    font-weight: 400;
    font-size: 13px;
  }

  .detail_page_personal h3 {
    font-weight: 500;
    font-size: 20px;
  }

  .detail_page_personal {
    padding: 20px;
  }

  /* .image_round {
  width: 260px;
  height: 260px;
} */
  .round-info h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }

  .newsEvents {
    padding: 25px 0;
  }

  .tile-h {
    margin-bottom: 30px !important;
  }

  .main_cm {
    padding-top: 20px;
  }

  /* .ssd{
  display: none;
} */
  .tile-h.sp-h {
    margin-bottom: 0px !important;
  }

  .banner {
    padding-bottom: 0;
    overflow: hidden;
  }

  .homeBanner>svg {
    top: -213px;
    width: 100%;
    right: -38px;
  }

  .tab-co .single_profile {
    width: 100%;
  }

  .booking_block.responsive-booking-blck .img_bk {
    width: 35%;
    margin: 0;

  }

  .booking_d {
    width: 60%;
  }

  .booking_block.responsive-booking-blck .booking_d {
    padding-top: 20px;
  }

  .booking_block.responsive-booking-blck .bt_bk {
    margin-left: auto;
    width: 100%;
    padding: 0 10px;
  }

  .booking_block.responsive-booking-blck .bt_bk div {
    width: 100%;
    display: flex;
    gap: 12px;
  }

  .booking_block.responsive-booking-blck .img_bk img {
    min-height: 125px;
  }

  .details_b p b,
  .details_b p span {
    font-size: 13px;
    line-height: 27px;
  }

  .b_det {
    width: 100%;
    max-width: 100%;
  }

  .banner_det p,
  .banner_det b,
  .banner_det span {
    font-size: 16px;
  }

  .comment-block p img {
    float: right;
    width: 60px !important;
    height: 60px;
    border-radius: 50%;
    margin-left: 15px;
    margin-bottom: 15px;
  }

  .flex_news {
    gap: 10px;

  }

  .box-news {
    width: 47.5%;

  }

  .box-news img {
    width: 100%;
    height: 140px !important;
  }

  .summary_doc_info {
    flex-wrap: wrap;
  }

  .summary_doc_info img {
    width: 60%;
    margin-bottom: 0;
    max-height: 200px;
    object-fit: contain;
    object-position: left;
  }

  .info_d_c p {
    width: 100%;
    flex-wrap: wrap;
  }

  .info_d_c p span {
    width: auto;
    display: block;
  }

  .left_info>span {
    word-break: break-word;
  }

  .name_sum {
    width: 100%;
  }

  .fl-ed {
    justify-content: center;
  }

  .showpopup .buttons_boo button {
    padding: 9px 0;
    width: 32%;
    font-size: 13px;
    border-radius: 10px;
    border: unset;
  }

  a.drp-clc svg {
    height: 15px !important;
  }

  .bf>div>span:last-child {
    max-width: 60%;
  }

  b.verified-fam,
  b.not-verified-fam {
    font-size: 10px;
  }

  .name_fm {
    width: 55%;
  }

  .fm_actions {
    width: 40%;
  }

  .fm_actions button {
    font-size: 10px;
  }

  a.drp-clc svg {
    height: 13px !important;
  }

  a.drp-clc svg:last-child {
    height: 10px !important;
  }

  h2.soryText {
    font-size: 18px;
    padding-top: 30px;
    min-height: 30vh;
    word-break: break-word;
  }

  .profile_det {
    max-width: 300px;
  }

  .form-sec h1 {
    font-size: 18px;
    margin-bottom: 12px;
    max-width: 60%;
    word-break: break-word;
  }

  .buttons_boo.nobordr {
    border: unset;
  }

  a.bcktoHome.router-link-active {
    font-size: 10px;
    max-width: 95px;
    padding: 5px;
  }

  /* .basic_info h2 {
  font-size: 15px;
}
.basic_info span {
  font-size: 12px;
  line-height: 19px;
}
.loc p {
  font-size: 11px;
  line-height: 20px;
}
.loc svg {
  height: 12px;
} */
  /* .s_img img {
  width: 100%;
  max-height: 155px;
  min-height: 155px;
} */
  .fav_list {
    padding: 0;
  }

  .booking_d .loc p {
    font-size: 11px;
    line-height: 20px;
  }

  .booking_bar_info .img_bk img {
    max-height: 125px;
    min-height: 100px;
    max-width: 130px;
  }

  .bf span {
    font-size: 14px;
    line-height: 30px;
  }

  .booking_bar_info .img_bk {
    width: 35%;
  }

  .Appointment_Form h2 {
    font-size: 18px;
    margin: 0;
  }

  .fam_book .family_Block {
    padding-top: 20px;
  }

  .checkb input {
    top: 23px;
  }

  .checkb input {
    left: 5px;
  }

  .fam_book .family_Block {
    padding-left: 35px;
  }

  button.now_book {
    margin: 35px auto 0;
    display: block;
    padding: 10px 10px;
    width: 100%;
  }

  .fam_book .name_fm h2 {
    font-size: 15px;
    margin-top: 5px;
  }
}

/* ***************************************************************** */
@media only screen and (max-width: 400px) {
  .banner-content img {
    max-width: 211px;
    position: relative;
    right: 0;
    bottom: 0;
    margin: 27% 0 -73px;
    margin-left: auto;
    margin-right: -15%;
  }

  .profile_det {
    max-width: 280px;
  }

  .qualifictn {
    font-weight: 400;
    font-size: 10px;
  }

  .booking_d button {
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: black;
    width: 85px;
    height: 20px;
    border: unset;
  }

  p.time_bk {
    font-size: 11px;
    line-height: 17px;
    padding: 0;
    margin: 5px 0 0;
  }

  .bt_bk button {
    font-size: 8px;
    padding: 6px;
    margin: 7px 0;
  }

  /* .provider > div {
  width: 100%;
} */
  /* span.spnsrd {
  position: absolute;
  right: 5px;
  top: -55px;
} */
  .booking_date_det h2 {
    margin: 0;
    font-size: 18px;
  }

  .flex_news {
    gap: 15px;
  }

  .bf>div>span:last-child {
    max-width: 53%;
  }

  .rel {
    color: #000;
    font-size: 13px;
  }

}

/* ***************************************************************** */
@media only screen and (max-width: 360px) {

  .btns .fif,
  .btns .full_w {
    width: 100%;
  }

  .details_b p {
    display: flex;
    /* align-items: center; */
    gap: 0px;
    flex-wrap: wrap;
  }

  .details_b p b {
    width: 100%
  }

  .box-news {
    width: 47%;
  }

}

/* ***************************************************************** */
@media only screen and (max-width: 340px) {
  .button_confm_sum {
    flex-wrap: wrap;
  }

  .button_confm_sum button {
    width: 100%;
  }
}