@media (max-width: 991px) {
    .bookigs-details-single h3{
        position: relative;
    }
    .badge{
        position: absolute;
        right: 0;
        top: -28px;
    }
    .bookingdetail{
        padding: 30px 0px 10px!important;
    }
}