
.grey-open{
    color: grey;
    position: absolute;
    top: 28px;
    right: 16px;
}
.changpswd .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width: 100% !important;
}
.changpswd .css-i44wyl {
    width: 100% !important;
  }