.myfamily-box {
    border-radius: 10px;
    background: #FFF;
    padding: 20px;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.10);
    margin-bottom: 10px;
}

.myfamily-box h4 {
    font-size: 20px;
    font-weight: 700;
    word-break: break-word;
    margin-bottom: 15px;
}

b.not-verified-fam {
    color: #00bcd4;
    cursor: pointer;
    font-weight: normal;
}

b.not-verified-fam i {
    background: #00bcd4;
    color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 11px;
    text-align: center;
    padding-top: 3px;
}

.verified-fam {
    color: green;
    cursor: pointer;
    font-weight: normal;
}

.verified-fam i {
    background: green;
    color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 11px;
    text-align: center;
    padding-top: 3px;
}
.bookingdetail{
    padding-bottom: 20px;
}
.textarea {
    margin-top: 20px;
}

.textarea label {
    width: 100%;
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.textarea textarea {
    border-radius: 4px;
    border: 1px solid var(--grey-aa-large, #949494);
    background: #fff;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: 10px 0 0;
    height: 112px;
    resize: none;
    color: rgba(0, 0, 0, 0.6);
    outline: 0;
}
input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
    -webkit-box-shadow: inset 0 0 0 30px #fff!important;
}

.add_family{
    width: 30%;
    background: #C70419;
    background: #ff283e;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 15px;
    border: none;
    margin: 0px 10px 10px 0px;
}