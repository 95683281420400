.my-account {
  border-radius: 10px;
  background: #fff;
  padding: 30px 20px;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
}

.my-account h2 {
  color: #333;
  font-style: normal;
  line-height: normal;
  margin-bottom: 20px;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.my-account .col-6>div {
  width: 100%;
}

.textfield-row-main {
  margin-bottom: 15px;
}

.col-6.select>div {
  margin: 0;
}

.textfield-row-main fieldset {
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #949494;
  border-top: 0;
}

.textfield-row-main .datepicker input {
  padding-left: 0 !important;
}

.textfield-row-main .datepicker label {
  left: -13px;
}

.textfield-row-main .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 14px 1px;
}

.textfield-row-main svg {
  position: absolute;
  right: 0;
  width: 18px;
  bottom: -12px;
}

.save:hover {
  background: transparent;
  color: #d2042d;
  border: 1px solid #d2042d;
  outline: 0;
}

.save {
  background: #d2042d;
  color: #fff;
  font-size: 18px;
  border: 1px solid #d2042d;
  padding: 8px 40px;
  border-radius: 10px;
  width: 50%;
  margin: 40px auto 10px;
  display: block;
}

.accout_sidebar.left-sidebarr {
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 10px;
}

.accout_sidebar.left-sidebarr ul {
  list-style: none;
  padding-left: 10px;

}

.accout_sidebar.left-sidebarr li {
  margin: 0 0 30px;
  text-decoration: none;
}

.accout_sidebar.left-sidebarr li a {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #333;
  cursor: pointer;
  text-decoration: none;
}

.accout_sidebar.left-sidebarr li a:hover {
  color: #ff2046 !important;
}

.select .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
  background-color: transparent !important;
}
input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
  -webkit-box-shadow: inset 0 0 0 30px #fff!important;
}
.accout_sidebar.left-sidebarr i{
  font-size: 18px;
}
.errorspan{
  color: red;
  font-size: x-small;
}
.datepicker.width-change div{
  width: unset!important;
}