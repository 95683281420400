.bookingsummary-main {
    padding: 20px 0;
  }
  .booking{
      border-radius: 10px;
      background: #FFF;
      padding: 30px 20px;
      box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.10);
  }
  .booking-detail{
      border-radius: 10px;
      background: #FFF;
      padding: 30px 20px;
      box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.10);
  }
  .booking h2{
      text-align: center;
      font-weight: 500;
      color: #333333;
      font-size: 26px;
      margin-bottom: 50px;
      font-family: Poppins, sans-serif;
  }
  .description{
      padding-bottom: 20px;
      color: #000;
      font-size: 18px;
      font-weight: normal;
      font-family: Poppins, sans-serif;
      word-wrap: break-word;
  }
  .edit{
      background: #D2042D;
      color: #FFF;
      font-size: 16px;
      border: 1px solid #D2042D;
      padding: 8px 40px;
      border-radius: 10px;
      width: 70%;
  }
  .edit:hover{
      background: transparent;
      color: #D2042D;
      border: 1px solid #D2042D;
      outline: 0;
  }
  .confirm:hover{
      background: transparent;
      color: #339511;
      border: 1px solid #339511;
      outline: 0;
  }
  .confirm{
      background: #339511;
      color: #FFF;
      font-size: 16+px;
      border: 1px solid #339511;
      padding: 8px 40px;
      border-radius: 10px;
      width: 70%;
  }
  .booking-detail img{
      margin: auto;
      border-radius: 20px;
      display: block;
      width: 230px;
      height: auto;
  }
  .booking-detail h4{
      color: #414141;
      font-size: 20px;
      margin-top: 30px;
      font-weight: 500;
      margin-bottom: 5px;
  }
  .booking-detail p{
      color: #414141;
      font-weight: normal;
      font-size: 17px;
  }