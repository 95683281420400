@media (max-width: 991px) {
    .booking h2 {
        text-align: center;
        font-size: 22px;
        margin-bottom: 30px;
    }
    .description {
        padding-bottom: 15px;
        font-size: 14px;
    }
    .button-edit{
        justify-content: center!important;
        margin-bottom: 10px!important;
        font-size: 14px!important;
    }
    .confirm{
        margin-bottom: 10px!important;
        margin: auto;
    }
}