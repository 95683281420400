@media (max-width: 991px) {
    .booking-confirmed-main {
        width: 100%;
        margin: 20px auto;
    }
    .date{
        margin-top: 10px;
    }
    .button-confirm{
        justify-content: center!important;
    }
}