.booking-confirmed-main {
    width: 60%;
    display: block;
    border-radius: 10px;
    background: #fff;
    padding: 30px 30px;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
    margin: 100px auto;
  }
  .booking-confirmed-main img {
    margin: auto;
    display: block;
  }
  .booking-confirmed-main h2 {
    text-align: center;
    font-size: 28px;
    margin-top: 15px;
  }
  .booking-confirmed-main p {
    text-align: center;
  }
  .name-details{
      background: #fff;
      padding: 20px;
      box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
  }
  .date{
      background:#C70419;
      padding: 20px;
      border-radius: 5px;
      height: 194px;
  }
  .date h4{
      color: #fff;
      font-size: 16px;
      text-align: center;
  }
  